import { History } from "history";

export default class NavigationHelper {
    private static history: History;

    public static setHistory(history: History) {
        this.history = history;
    }

    public static pushRoute(route: RoutingType, routeString: string = "") {
        this.history.push(`${NavigationHelper.getRoute(route)}${routeString}`);
    }

    public static getRoute(route: RoutingType) {
        switch (route) {
            case "DASHBOARD":
                return "/";
            case "PRODUCTS":
                return "/products";
            case "CUSTOMER":
                return "/customer";
            case "BOOKING":
                return "/booking";
            case "CONFIRM":
                return "/confirm";
            case "WORKSHEET":
                return "/worksheet";
            case "LOGIN":
                return "/login";
            case "FORGOT":
                return "/forgot";
            case "CREATE":
                return "/users/create";
            case "ASSIGNMENTS":
                return "/assignments";
            case "SEARCHBYPHONE":
                return "/assignments/:phoneOrMail";
            case "OFFERS":
                return "/offers";
            case "PENDING":
                return "/pending";
            case "RECEIPT":
                return "/receipt";
            case "ORDERS":
                return "/orders";
            case "USERS":
                return "/users";
            case "EDIT":
                return "/users/edit";
            case "OLD_PRODUCTS":
                return "/oldproducts";
            case "CUSTOMER_SATISFACTION":
                return "/customersatisfaction";
            case "WEB_ORDERS":
                return "/weborders";
            case "ZIPCODE_AVALABILITY":
                return "/zipcodes";
            case "DEPARTMENTS":
                return "/departments";
            case "MOBILE_SERVICE_MAP":
                return "/mobile-service-map";
            case "DELIVERY_TIME":
                return "/delivery-time";
            case "CALENDAR":
                return "/calender";
            case "COMPLAINTS":
                return "/complaints";
            case "AVAILABLE_SLOTS":
                return "/available-slots";
            case "AVAILABLE_SLOTS_JUTLAND":
                return "/available-slots-jutland";
            case "AVAILABLE_SLOTS_FYN_SJAELLAND":
                return "/available-slots-fyn-sjaelland";
            case "DEPARTMENT_RULE_SET":
                return "/department-rule-set";
            case "DEBITOR_RULE_SET":
                return "/debitor-rule-set";
            case "CAPACITY_OPTIMIZATION":
                    return "/capacity-optimization";
        }
    }
    

    // Only works with: window.location.pathname
    public static lookup(path: string): RoutingType {
        switch (path) {
            case "/capacity-optimization":
                return "CAPACITY_OPTIMIZATION";
            case "/products":
                return "PRODUCTS";
            case "/customer":
                return "CUSTOMER";
            case "/booking":
                return "BOOKING";
            case "/confirm":
                return "CONFIRM";
            case "/worksheet":
                return "WORKSHEET";
            case "/login":
                return "LOGIN";
            case "/forgot":
                return "FORGOT";
            case "/users/create":
                return "CREATE";
            case "/assignments":
                return "ASSIGNMENTS";
            case "/offers":
                return "OFFERS";
            case "/pending":
                return "PENDING";
            case "/receipt":
                return "RECEIPT";
            case "/orders":
                return "ORDERS";
            case "/users":
                return "USERS";
            case "/users/edit":
                return "EDIT";
            case "/weborders":
                return "WEB_ORDERS";
            case "/zipcodes":
                return "ZIPCODE_AVALABILITY";
            case "/departments":
                return "DEPARTMENTS";
            case "/mobile-map-service":
                return "MOBILE_SERVICE_MAP";
            case "/delivery-time":
                return "DELIVERY_TIME";
            case "/available-slots":
                return "AVAILABLE_SLOTS";
            case "/available-slots-jutland":
                return "AVAILABLE_SLOTS_JUTLAND";
            case "/available-slots-fyn-sjaelland":
                return "AVAILABLE_SLOTS_FYN_SJAELLAND";
            case "/department-rule-set":
                return "DEPARTMENT_RULE_SET";
            case "/debitor-rule-set":
                return "DEBITOR_RULE_SET";
            default:
                return "DASHBOARD";
        }
    }
}
