import * as React from "react";
import { useCallback, useState } from "react";
import SendTextWindow from "../../../base/Notifications/SendTextWindow";
import SendEmailWindow from "../../../base/Notifications/SendEmailWindow";
import SetOnHoldDialog from "../SetOnHoldDialog";
import SetOnHoldCommentDialog from "../SetOnHoldDialog/SetOnHoldCommentDialog";
import ConfirmDialog from "../../../dialogs/confirm";
import "./style.css";
import {
    PrimaryButton,

} from "@fluentui/react";
import FinishOrder from "./FinishOrder";

interface Props{
    orderStatus: OrderStatus;
    order: Order;
    products: ProductGroup[];
    user: UserInformation;
    query: { searchIn?: string; term?: string };
    sendSms: (model: NotificationModel) => void;
    sendNotificationEmail: (model: NotificationModel) => void;
    onSaveOrderClick: (status?: OrderStatus) => void;
    saveOrder: (order: Order) => void;
    updateAdditionalData: (additionalDataFieldName: string, newValue: any) => void;
    updateSearchProductQuery: (searchIn?: string, term?: string) => void;
    updateSearchList: (query: { searchIn: string; term: string }, merge: boolean ) => void;
}

function OrderButtons(props: Props) {

    const [hideAreYouDeleteDialog, setHideAreYouDeleteDialog] = useState<boolean>(true);
    const [hideSetOnHoldDialog, setHideSetOnHoldDialog] = useState<boolean>(true);
    const [hideSetOnHoldCommentdDialog, setHideSetOnHoldCommentDialog] = useState<boolean>(true);
  
    const toggleSetOnHoldCommentDialog = useCallback(() => setHideSetOnHoldCommentDialog(!hideSetOnHoldCommentdDialog), [hideSetOnHoldCommentdDialog]);
   
    const toggleHideAreYouDeleteDialog = useCallback(() => setHideAreYouDeleteDialog(!hideAreYouDeleteDialog), [hideAreYouDeleteDialog]);

  
    const onConfirmSetOnHold = useCallback(
        (onHoldComment: string) => {
            const order: Order = {
                ...props.order,
                additionalData: {
                    ...props.order.additionalData,
                    orderStatus: "ON_HOLD",
                    onHoldComment: onHoldComment
                }
            };
            props.saveOrder(order);
            setHideSetOnHoldDialog(true);
        },
        [props.order]
    );
    const onConfirmOnHoldComment = useCallback(() => {
        props.onSaveOrderClick();
        setHideSetOnHoldCommentDialog(true);
    }, [props]);
    const onConfirmDelete = useCallback(() => {
        props.onSaveOrderClick("CANCELLED");
        setHideAreYouDeleteDialog(true);
    }, [props.order]);
    const toggleSetOnHoldDialog = useCallback(() => {
        if (
            !props.order.additionalData.worker &&
            !props.order.additionalData.windowManufacturer
        ) {
            alert("Du skal vælge en værdi for de markerede felter !");
            return;
        } else if (
            props.order.additionalData.worker &&
            props.order.additionalData.windowManufacturer
        ) {
            setHideSetOnHoldDialog(!hideSetOnHoldDialog);
        } else {
            props.order.additionalData.worker
                ? alert("Du mangler at vælge en rudefabrikat !")
                : alert("Du mangler at vælge en montør !");
            return;
        }
    }, [
        hideSetOnHoldDialog,
        props.order.additionalData.worker,
        props.order.additionalData.windowManufacturer
    ]);
    const onSetOrderStatus = (status?: OrderStatus) => () => {
        if (props.order.timeAndPlace.from && props.order.timeAndPlace.to) {
            props.onSaveOrderClick(status);
        } else {
            alert("Du mangler at vælge en tid!");
        }
    };
   
    const saveBtn = (
        <>
            <PrimaryButton text="Gem" onClick={onSetOrderStatus()} />
        </>
    );
    let buttons = null;
    if (props.orderStatus !== "FINISHED" && props.orderStatus !== "CANCELLED") {
       
        const isNotServiceCall = props.order.additionalData.orderStatus !== "SERVICE_CALL";
        buttons = (
            <>
                {isNotServiceCall && (
                    <>
                        <div className="styledButtons">
                            {props.order.additionalData.orderStatus !==
                            "ON_HOLD" ? (
                                <PrimaryButton
                                    className="btn-set-on-hold"
                                    onClick={toggleSetOnHoldDialog}
                                    text="Sæt på hold"
                                />
                            ) : (
                                <PrimaryButton
                                    className="btn-set-on-hold"
                                    onClick={toggleSetOnHoldCommentDialog}
                                    text="Kommenter"
                                />
                            )}
                        </div>
                        <div className="cancel_button">
                            <PrimaryButton
                                onClick={toggleHideAreYouDeleteDialog}
                                text="Slet opgave"
                            />
                        </div>
                    </>
                )}
                {saveBtn}
                {/* <PrimaryButton text="SMS - Opgave udført" onClick={props.handleSendClick}/> */}
                {isNotServiceCall && (
                    <>
                        {props.order.customer.allowSms ? (
                            <SendTextWindow
                                order={props.order}
                                sendSms={props.sendSms}
                                notificationType="REPAIR_COMPLETE"
                                buttonText="SEND 'Reparation er færdig' SMS"
                            />
                        ) : (
                            <div className="cancel--full disabled cancel_button">
                                <PrimaryButton text="SMS ikke tilladt" />
                            </div>
                        )}
                        {props.order.customer.allowEmail ? (
                            <SendEmailWindow
                                order={props.order}
                                sendNotificationEmail={
                                    props.sendNotificationEmail
                                }
                                notificationType="REPAIR_COMPLETE"
                                buttonText="SEND 'Reparation er færdig' EMAIL"
                            />
                        ) : (
                            <div className="cancel--full disabled cancel_button">
                                <PrimaryButton text="EMAIL ikke tilladt" />
                            </div>
                        )}
                    </>
                )}
                <FinishOrder 
                    isNotServiceCall={isNotServiceCall} 
                    onSaveOrderClick={props.onSaveOrderClick}
                    onSetOrderStatus={onSetOrderStatus}
                    order={props.order}
                    orderStatus={props.orderStatus}
                    products={props.products}
                    query={props.query}
                    updateSearchList={props.updateSearchList}
                    updateSearchProductQuery={props.updateSearchProductQuery}
                    user={props.user}
                />
            </>
        );
    } else if (props.orderStatus === "FINISHED") {
        buttons = saveBtn;
    }
    if(buttons)
        {
            return <>
            <ConfirmDialog
                    hideDialog={hideAreYouDeleteDialog}
                    onDismiss={toggleHideAreYouDeleteDialog}
                    onConfirm={onConfirmDelete}
                    title="Slet ordre"
                    subText="Er du sikker på du vil slette ordren?"
                    confirmText="Ja"
                    cancelText="Nej"
                />
              
                <SetOnHoldDialog
                    hideDialog={hideSetOnHoldDialog}
                    onDismiss={toggleSetOnHoldDialog}
                    onConfirm={onConfirmSetOnHold}
                    title="Sæt på hold"
                    subText=""
                    confirmText="Ja, Sæt på hold"
                    cancelText="Nej, Gå tilbage"
                    switchButtons={true}
                />
                <SetOnHoldCommentDialog
                    hideDialog={hideSetOnHoldCommentdDialog}
                    onDismiss={toggleSetOnHoldCommentDialog}
                    onConfirm={onConfirmOnHoldComment}
                    updateAdditionalData={props.updateAdditionalData}
                    title="Kommenter"
                    subText=""
                    confirmText="Gem"
                    cancelText="Annuller"
                    switchButtons={false}
                />
             
                {buttons}
                </>
        }
    return buttons;
}

export default OrderButtons;