import { Action, Dispatch } from "redux";
import api from "../../api";
interface GetOrderImagesAction extends Action
{
    type: "GET_ORDER_IMAGES";
    images: OrderImage[];
}
interface UpdateOrderImageAction extends Action
{
    type: "GET_ORDER_IMAGE";
    src: string;
    orderId: string;
    imageType: string;
}

export const uploadImage = (file: File, fileName: string, orderId: string, imageType: string) => async (dispatch: Dispatch) => 
    {
        const src = await api.MediaApi.uploadImage(file, fileName, orderId, imageType);
        const action = { src, type: "GET_ORDER_IMAGE", orderId, imageType} as UpdateOrderImageAction;
        dispatch(action);
    }

export const getImages = (orderId: string, debitorId: string) => async (dispatch: Dispatch) => 
{
    const images = await api.MediaApi.getOrderImages(orderId, debitorId);
    const action = { images, type: "GET_ORDER_IMAGES"} as GetOrderImagesAction;
    dispatch(action);
}

export type Actions =
    GetOrderImagesAction |
    UpdateOrderImageAction;
