import produce from "immer";
import { Actions } from "./action";


export interface ImageOrderState {
   images: OrderImage[];
}

const initialState: ImageOrderState = {
    images: []
};

export const reducer = (uiState = initialState, action: Actions): ImageOrderState =>
    produce(uiState, draft => {
        switch (action.type) {
            case "GET_ORDER_IMAGES": {
                draft.images = action.images;
                return;
            }
            case "GET_ORDER_IMAGE": {
                const index = draft.images.findIndex(x => x.imageType === action.imageType);
                if(!draft.images[index].url || action.imageType !== "Custom")
                {
                    draft.images[index].url = [];
                }
                if(draft.images[index].url)
                {
                    (draft.images[index].url as string[]).push(action.src);
                }
                return;
            }
        }
    });

export const orderImages = (state: ImageOrderState) => state.images;

export default reducer;
