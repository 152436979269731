import { useCallback, useState, useEffect } from "react";
import api from "../../api";
import {
    Checkbox,
    DefaultButton,
    Dialog,
    Dropdown,
    IDropdownOption,
    Label,
    PrimaryButton,
    TextField
} from "@fluentui/react";
import "./style.css";
import * as React from "react";
import DropdownPanel from "../base/DropdownPanel";

interface Props {
    isRuleDialog: boolean;
    onToggleDialog: () => void;
    hidden: boolean;
    row?: {
        id: string;
        ruleName?: string;
        ruleSetName?: string;
        carMake?: string;
        carModel?: string;
        isWorkAllowed?: boolean;
        minimumWorkLength?: number;
        departments?: DepartmentRuleSet[];
        rules?: Rule[];
        alertMessage?: string;
    };
}

function CustomRuleDialog(props: Props) {
    const emptyDepartments: DepartmentRuleSet[] = [];
    const emptyRules: Rule[] = [];
    const baseModel = {
        id: "",
        carMake: "",
        isWorkAllowed: false,
        disallowBackWindowWork: false,
        disallowFrontWindowWork: false,
        disallowSideWindowWork: false,
        disallowRoofWindowWork: false,
        carModel: "",
        minimumWorkLength: 0,
        ruleSetName: "",
        departments: emptyDepartments,
        rules: emptyRules,
        alertMessage: ""
    };

    const entityModel = { ...baseModel, ...(props.row || {}) };

    const [entity, setEntity] = useState(() => {
        return entityModel;
    });

    const [carManufactures, setCarManufacturers] = useState<CarManufacture[]>(
        []
    );
    const [carModels, setCarModels] = useState<CarManufactureModel[]>([]);
    const [rules, setRules] = useState<Rule[]>([]);
    const [departments, setDepartments] = useState<Department[]>([]);

    if (props.row && entity.carMake !== entityModel.carMake)
        setEntity(entityModel);

    if (props.row && entity.ruleSetName !== entityModel.ruleSetName)
        setEntity(entityModel);

    useEffect(() => {
        fetchCarModels(entity.carMake);
    }, [entity.carMake]);

    useEffect(() => {
        if (props.isRuleDialog) {
            api.CarAPI.getCarManufactures().then(data => {
                setCarManufacturers([...carManufactures, ...data]);
            });
        }
    }, []);

    const fetchCarModels = (carMake: string) => {
        if (props.isRuleDialog) {
            const selectedCar = carManufactures.find(
                x => x.manuName === carMake
            );
            if (selectedCar) {
                api.CarAPI.getCarManufactureModels(selectedCar.manuId).then(
                    data => {
                        setCarModels([...data]);
                    }
                );
            }
        }
    };

    if (!carModels.length) {
        fetchCarModels(entityModel.carMake);
    }

    useEffect(() => {
        if (departments.length < 1 && rules.length < 1) {
            api.DepartmentApi.getDepartments().then(data => {
                setDepartments([...data]);
            });
            fetchRules();
        }
    });

    useEffect(() => {
        function handleRulesRefreshEvent() {
            fetchRules();
        }

        document.addEventListener("RULES_REFRESH", handleRulesRefreshEvent);

        return () => {
            document.removeEventListener(
                "RULES_REFRESH",
                handleRulesRefreshEvent
            );
        };
    });

    const fetchRules = () => {
        api.DepartmentRuleSetApi.getRulesPaginated(100000, 0).then(data => {
            setRules([...data.rules]);
        });
    };

    const changeBrand = (
        event: React.FormEvent<HTMLDivElement>,
        option: IDropdownOption
    ) => {
        setEntity({ ...entity, carMake: option.text });
    };

    const changeModel = (
        event: React.FormEvent<HTMLDivElement>,
        option: IDropdownOption
    ) => {
        setEntity({ ...entity, carModel: option.text });
    };
   
    const changeWorkAllowed = (e: React.SyntheticEvent<HTMLInputElement>,checked: boolean) => setEntity({ ...entity, isWorkAllowed: checked });
    const changeDisallowBackWindowWork = (e: React.SyntheticEvent<HTMLInputElement>,checked: boolean) => setEntity({ ...entity, disallowBackWindowWork: checked });
    const changeDisallowFrontWindowWork = (e: React.SyntheticEvent<HTMLInputElement>,checked: boolean) => setEntity({ ...entity, disallowFrontWindowWork: checked });
    const changeDisallowSideWindowWork = (e: React.SyntheticEvent<HTMLInputElement>,checked: boolean) => setEntity({ ...entity, disallowSideWindowWork: checked });
    const changeDisallowRoofWindowWork = (e: React.SyntheticEvent<HTMLInputElement>,checked: boolean) => setEntity({ ...entity, disallowRoofWindowWork: checked });
    const channegMinimumWorkTime = (
        event: React.SyntheticEvent<HTMLInputElement>,
        newValue: string
    ) => {
        setEntity({ ...entity, minimumWorkLength: Number(newValue) });
    };

    const getCarMakeKeyByName = (name: string): number => {
        const result = carManufactures.find(x => x.manuName === name);
        if (result) return result.manuId as number;
        return 0;
    };

    const getCarModeKeylByName = (name: string): number => {
        const result = carModels.find(x => x.modelName === name);
        if (result) return result.modelId;
        return 0;
    };

    const changeDepartment = (value: Department) => (
        e: React.SyntheticEvent<HTMLInputElement>,
        checked: boolean
    ) => {
        if (checked) {
            setEntity({
                ...entity,
                departments: [
                    ...entity.departments,
                    {
                        departmentId: value.id as string,
                        departmentName: value.name
                    }
                ]
            });
        } else {
            setEntity({
                ...entity,
                departments: [
                    ...entity.departments.filter(
                        x => x.departmentId !== value.id
                    )
                ]
            });
            if (entity.id && value.id)
                api.DepartmentRuleSetApi.deleteDepartmentFromSet(
                    entity.id,
                    value.id
                );
        }
    };

    const changeRules = (value: Rule) => (
        e: React.SyntheticEvent<HTMLInputElement>,
        checked: boolean
    ) => {
        if (checked) {
            setEntity({
                ...entity,
                rules: [
                    ...entity.rules,
                    {
                        id: value.id as string,
                        ruleName: value.ruleName,
                        isWorkAllowed: value.isWorkAllowed,
                        carMake: value.carMake,
                        disallowBackWindowWork: value.disallowBackWindowWork,
                        disallowFrontWindowWork: value.disallowFrontWindowWork,
                        disallowSideWindowWork: value.disallowSideWindowWork,
                        disallowRoofWindowWork: value.disallowRoofWindowWork,
                    }
                ]
            });
        } else {
            setEntity({
                ...entity,
                rules: [...entity.rules.filter(x => x.id !== value.id)]
            });

            if (entity.id && value.id)
                api.DepartmentRuleSetApi.deleteRuleFromSet(entity.id, value.id);
        }
    };

    const changeName = (
        event: React.SyntheticEvent<HTMLInputElement>,
        newValue: string
    ) => {
        setEntity({ ...entity, ruleSetName: newValue });
    };

    const changeAlertMessage = (
        event: React.SyntheticEvent<HTMLInputElement>,
        newValue: string
    ) => {
        setEntity({ ...entity, alertMessage: newValue });
    };

    const onNewRuleClick = useCallback(() => {
        if (entity.carMake) {
            const workAllowed = entity.isWorkAllowed
                ? "Work_Allowed"
                : "Work_Not_Allowed";
            const newRule: BaseRule = {
                ruleName: `${workAllowed}_${entity.carMake}${
                    entity.carModel ? `_${entity.carModel}` : ""
                }`,
                carMake: entity.carMake ? entity.carMake : "",
                carModel: entity.carModel,
                isWorkAllowed: entity.isWorkAllowed
                    ? entity.isWorkAllowed
                    : false,
                   
                minimumWorkLength: entity.minimumWorkLength,
                disallowBackWindowWork: entity.disallowBackWindowWork,
                disallowFrontWindowWork: entity.disallowFrontWindowWork,
                disallowSideWindowWork: entity.disallowSideWindowWork,
                disallowRoofWindowWork: entity.disallowRoofWindowWork,
            };

            api.DepartmentRuleSetApi.createOrUpdateRule(
                props.row ? props.row.id : "",
                newRule
            )
                .then(e => {
                    document.dispatchEvent(new Event("RULES_REFRESH"));
                    props.onToggleDialog();
                })
                .catch(e => {
                    // show error message to user.
                });
        }
    }, [entity]);

    const convertRuleSetToCreateModel = (array: DepartmentRuleSet[]) => {
        const convertedArray: string[] = [];
        array.map(item => {
            if (item.departmentId) convertedArray.push(item.departmentId);
        });
        return convertedArray;
    };

    const convertRuleToCreateModel = (array: Rule[]) => {
        const convertedArray: string[] = [];
        array.map(item => {
            if (item.id) convertedArray.push(item.id);
        });
        return convertedArray;
    };

    const onNewRuleSetClick = useCallback(() => {
        if (
            entity.departments.length > 0 &&
            entity.rules.length > 0 &&
            entity.alertMessage &&
            entity.ruleSetName
        ) {
            const newRuleSet: RuleSetCreateModel = {
                alertMessage: entity.alertMessage,
                ruleSetName: entity.ruleSetName,
                departmentIds: convertRuleSetToCreateModel(entity.departments),
                ruleIds: convertRuleToCreateModel(entity.rules)
            };

            api.DepartmentRuleSetApi.createOrUpdateRuleSet(
                props.row ? props.row.id : "",
                newRuleSet
            )
                .then(e => {
                    document.dispatchEvent(new Event("RULESET_REFRESH"));
                    props.onToggleDialog();
                })
                .catch(e => {
                    // show error message to user.
                });
        } else {
            alert(
                "Kontrollere, om mindst én værdi er valgt for alle indtastningsfelter!"
            );
        }
    }, [entity]);

    const renderCustomRuleDialog = () => {
        const makeKey = getCarMakeKeyByName(entity.carMake);
        const modelKey = getCarModeKeylByName(entity.carModel);
        return (
            <Dialog hidden={props.hidden}>
                <Label className="dialog__label">Ny regler</Label>
                {carManufactures && (
                    <div className="carBrand_dropdown__container">
                        <Dropdown
                            calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                            disabled={props.row !== undefined}
                            title="Mærke"
                            placeholder={"Mærke"}
                            options={carManufactures.map(x => ({
                                key: x.manuId,
                                text: x.manuName
                            }))}
                            defaultSelectedKey={makeKey}
                            onChange={changeBrand}
                        />
                    </div>
                )}
                {carModels && (
                    <Dropdown
                        calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                        title="Model"
                        placeholder={"Model"}
                        options={carModels.map(x => ({
                            key: x.modelId,
                            text: x.modelName
                        }))}
                        onChange={changeModel}
                        defaultSelectedKey={modelKey}
                    />
                )}

                <Checkbox className="dialog__checkbox" onChange={changeWorkAllowed} label="Arbejde er tilladt" defaultChecked={entity.isWorkAllowed} />
                <Checkbox className="dialog__checkbox" onChange={changeDisallowFrontWindowWork} label="Forrudeskift er ikke tilladt" defaultChecked={entity.disallowFrontWindowWork} />
                <Checkbox className="dialog__checkbox" onChange={changeDisallowSideWindowWork} label="Siderudeskift er ikke tilladt" defaultChecked={entity.disallowSideWindowWork} />
                <Checkbox className="dialog__checkbox" onChange={changeDisallowBackWindowWork} label="Bagrudeskift er ikke tilladt" defaultChecked={entity.disallowBackWindowWork} />
                <Checkbox className="dialog__checkbox" onChange={changeDisallowRoofWindowWork} label="Tagrudeskift er ikke tilladt" defaultChecked={entity.disallowRoofWindowWork} />
                <div hidden={!entity.isWorkAllowed}>
                    <TextField
                        className="dialog__textField"
                        placeholder="minimumWorkHours"
                        onChange={channegMinimumWorkTime}
                        defaultValue={
                            entity.minimumWorkLength
                                ? entity.minimumWorkLength.toString()
                                : ""
                        }
                    />
                </div>
                <PrimaryButton
                    hidden={entity.carMake === ""}
                    className="dialog__createButton"
                    text={props.row ? "Gem Regler" : "Opret Ny Regler"}
                    onClick={onNewRuleClick}
                />
                <DefaultButton
                    className="dialog__cancelButton"
                    onClick={props.onToggleDialog}
                    text="Anuller"
                />
            </Dialog>
        );
    };

    const renderCustomRuleSetDialog = () => {
        return (
            <Dialog hidden={props.hidden}>
                <Label className="dialog__label">Ny reglersæt</Label>
                <TextField
                    className="dialog__textField"
                    placeholder="Reglersæt navn"
                    onChange={changeName}
                    defaultValue={entity.ruleSetName ? entity.ruleSetName : ""}
                ></TextField>
                <TextField
                    className="dialog__textField"
                    placeholder="Alert message"
                    onChange={changeAlertMessage}
                    defaultValue={
                        entity.alertMessage ? entity.alertMessage : ""
                    }
                ></TextField>
                <DropdownPanel changed={false} title={"Afdelinger"}>
                    <div className="custom_rule_dialog">
                        <ul>
                            {departments.map((x, index) => (
                                <li key={index}>
                                    <Checkbox
                                        className="checkbox_issues"
                                        label={x.name}
                                        onChange={changeDepartment(x)}
                                        checked={
                                            entity.departments.find(
                                                elem =>
                                                    elem.departmentId === x.id
                                            ) !== undefined
                                        }
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                </DropdownPanel>
                <DropdownPanel changed={false} title={"Regler"}>
                    <div className="custom_rule_dialog">
                        <ul>
                            {rules.map((x, index) => (
                                <li key={index}>
                                    <Checkbox
                                        className="checkbox_issues"
                                        label={x.ruleName + (x.minimumWorkLength? ` minWork: ${x.minimumWorkLength}`: "" )}
                                        onChange={changeRules(x)}
                                        checked={
                                            entity.rules.find(
                                                elem => elem.id === x.id
                                            ) !== undefined
                                        }
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                </DropdownPanel>
                <PrimaryButton
                    className="dialog__createButton"
                    text={props.row ? "Gem reglsæt" : "Opret Ny Reglsæt"}
                    onClick={onNewRuleSetClick}
                />
                <DefaultButton
                    className="dialog__cancelButton"
                    onClick={props.onToggleDialog}
                    text="Anuller"
                />
            </Dialog>
        );
    };

    return props.isRuleDialog
        ? renderCustomRuleDialog()
        : renderCustomRuleSetDialog();
}
export default CustomRuleDialog;
