import * as React from "react";
import Dashboard from "../dashboard";
import Products from "../products/ProductOverview";
import Confirmation from "../booking/Confirmation";
import WorkSheetInfo from "../worksheet/WorkSheetInfo";
import StatusMessageBar from "../base/StatusMessageBar";
import NavigationHelper from "../utils/NavigationHelper";
import Header from "./Header";
import Footer from "./Footer";
import CustomerInfo from "../booking/Customer/CustomerInfo";
import UserInfo from "../user/UserInfo";
import AuthRoute from "./AuthRoute";
import ListAssignments from "../assignments";
import Receipt from "../finish";
import Orders from "../orders/Orders";
import ExceptionPanel from "../base/ExceptionPanel";
import Users from "../user/Users";
import OldProducts from "../old_products/OldProducts";
import Departments from "../departments/Departments";
import MobileServiceMap from "../service_map/MobileServiceMap";
import Calendar from "../calendar";
import ComplaintReport from "../complaint/ComplaintReport";
import AvailableSlots from "../availableSlots";
import DepartmentRuleSet from "../departmentRuleSets";
import DeliveryTime from "../delivery_time";
import ZipcodeAvailability from "../zipcode"
import AvailableSlotsJutland from "../availableSlots/availableSlotsJutland";
import availableSlotsRegionFynSjaelland from "../availableSlots/availableSlotsRegionFynSjaelland";
import DebitorRuleSets from "../debitorRuleSets";
import CapacityOptimization from "../capacityOptimization";
import "../global.style.css";
import "./style.css";

const Page: React.FunctionComponent<null> = props => {
    // Check for these routes, to add a className
    const currentPath = location.pathname;
    const availableSlotsRoutes = [
        '/available-slots-jutland',
        '/available-slots-fyn-sjaelland'
    ];
    const isAvailableSlotsRegionRoute = availableSlotsRoutes.some(route => currentPath.includes(route));
    const mainContainerClassName = isAvailableSlotsRegionRoute ? "main_page__container available-slots-region-page" : "main_page__container";

    return (
        <>
            <div className={mainContainerClassName}>
                <Header />
                <StatusMessageBar />
                <ExceptionPanel />
                <AuthRoute
                    allowedUserRoles={[
                        "Adminstrator",
                        "CustomerService",
                        "Stock",
                        "Operation"
                    ]}
                    path={NavigationHelper.getRoute("DASHBOARD")}
                    exact={true}
                    component={Dashboard}
                />
                <AuthRoute
                    allowedUserRoles={[
                        "Adminstrator",
                        "CustomerService",
                        "Operation"
                    ]}
                    path={NavigationHelper.getRoute("PRODUCTS")}
                    exact={true}
                    component={Products}
                />
                <AuthRoute
                    allowedUserRoles={[
                        "Adminstrator",
                        "CustomerService",
                        "Operation"
                    ]}
                    path={`(${NavigationHelper.getRoute(
                        "CUSTOMER"
                    )}|${NavigationHelper.getRoute("BOOKING")})`}
                    exact={true}
                    component={CustomerInfo}
                />
                <AuthRoute
                    allowedUserRoles={[
                        "Adminstrator",
                        "CustomerService",
                        "Operation"
                    ]}
                    path={NavigationHelper.getRoute("CONFIRM")}
                    exact={true}
                    component={Confirmation}
                />
                <AuthRoute
                    allowedUserRoles={[
                        "Adminstrator",
                        "CustomerService",
                        "Operation"
                    ]}
                    path={NavigationHelper.getRoute("RECEIPT")}
                    exact={true}
                    component={Receipt}
                />
                <AuthRoute
                    allowedUserRoles={[
                        "Adminstrator",
                        "CustomerService",
                        "Operation",
                        "ReviewManager"
                    ]}
                    path={`${NavigationHelper.getRoute("WORKSHEET")}/:orderId`}
                    exact={true}
                    component={WorkSheetInfo}
                />
                <AuthRoute
                    allowedUserRoles={[
                        "Adminstrator",
                        "CustomerService",
                        "Operation"
                    ]}
                    path={NavigationHelper.getRoute("ASSIGNMENTS")}
                    exact={true}
                    component={ListAssignments}
                />
                <AuthRoute
                    allowedUserRoles={[
                        "Adminstrator",
                        "CustomerService",
                        "Operation"
                    ]}
                    path={NavigationHelper.getRoute("SEARCHBYPHONE")}
                    exact={true}
                    component={ListAssignments}
                />
                <AuthRoute
                    allowedUserRoles={["Adminstrator", "CustomerService", "Operation"]}
                    path={NavigationHelper.getRoute("OFFERS")}
                    exact={true}
                    component={ListAssignments}
                />
                <AuthRoute
                    allowedUserRoles={["Adminstrator", "ReviewManager"]}
                    path={NavigationHelper.getRoute("PENDING")}
                    exact={true}
                    component={ListAssignments}
                />
                <AuthRoute
                    allowedUserRoles={["Adminstrator", "ReviewManager"]}
                    path={NavigationHelper.getRoute("WEB_ORDERS")}
                    exact={true}
                    component={ListAssignments}
                />
                <AuthRoute
                    allowedUserRoles={["Adminstrator", "ReviewManager"]}
                    path={NavigationHelper.getRoute("ZIPCODE_AVALABILITY")}
                    exact={true}
                    component={ZipcodeAvailability}
                />
                <AuthRoute
                    allowedUserRoles={["Adminstrator", "TimeManagement"]}
                    path={NavigationHelper.getRoute("DEPARTMENTS")}
                    exact={true}
                    component={Departments}
                />
                 <AuthRoute
                    allowedUserRoles={["Adminstrator", "TimeManagement"]}
                    path={`${NavigationHelper.getRoute("DEPARTMENTS")}/:departmentId`}
                    exact={true}
                    component={Departments}
                />
                <AuthRoute
                    allowedUserRoles={[]}
                    path={NavigationHelper.getRoute("DELIVERY_TIME")}
                    exact={true}
                    component={DeliveryTime}
                />
                <AuthRoute
                    allowedUserRoles={[]}
                    path={`(${NavigationHelper.getRoute(
                        "LOGIN"
                    )}|${NavigationHelper.getRoute("FORGOT")})`}
                    exact={true}
                    component={UserInfo}
                />
                <AuthRoute
                    allowedUserRoles={["Adminstrator", "Operation", "Stock"]}
                    path={NavigationHelper.getRoute("ORDERS")}
                    exact={true}
                    component={Orders}
                />
                <AuthRoute
                    allowedUserRoles={["Adminstrator", "CustomerService", "Operation", "ReviewManager", "Stock"]}
                    path={NavigationHelper.getRoute("OLD_PRODUCTS")}
                    exact={true}
                    component={OldProducts}
                />
                <AuthRoute
                    allowedUserRoles={[
                        "Adminstrator",
                        "CustomerService",
                        "Operation"
                    ]}
                    path={`(${NavigationHelper.getRoute("PRODUCTS")}|
                    |${NavigationHelper.getRoute("BOOKING")}|
                    |${NavigationHelper.getRoute("CONFIRM")}|
                    |${NavigationHelper.getRoute("CUSTOMER")})`}
                    component={Footer}
                />
                <AuthRoute
                    allowedUserRoles={["Adminstrator"]}
                    path={NavigationHelper.getRoute("USERS")}
                    component={Users}
                />
                <AuthRoute
                    allowedUserRoles={["Adminstrator", "Complaint"]}
                    path={NavigationHelper.getRoute("COMPLAINTS")}
                    exact={true}
                    component={ComplaintReport}
                />
                <AuthRoute
                    allowedUserRoles={[
                        "Adminstrator",
                        "CustomerService",
                        "Stock",
                        "Operation"
                    ]}
                    path={NavigationHelper.getRoute("AVAILABLE_SLOTS")}
                    exact={true}
                    component={AvailableSlots}
                />
                <AuthRoute
                    allowedUserRoles={[
                        "Adminstrator",
                        "CustomerService",
                        "Stock",
                        "Operation"
                    ]}
                    path={NavigationHelper.getRoute("AVAILABLE_SLOTS_JUTLAND")}
                    exact={true}
                    component={AvailableSlotsJutland}
                />
                <AuthRoute
                    allowedUserRoles={[
                        "Adminstrator",
                        "CustomerService",
                        "Stock",
                        "Operation"
                    ]}
                    path={NavigationHelper.getRoute("AVAILABLE_SLOTS_FYN_SJAELLAND")}
                    exact={true}
                    component={availableSlotsRegionFynSjaelland}
                />
                <AuthRoute allowedUserRoles={["Adminstrator"]} path={NavigationHelper.getRoute("DEPARTMENT_RULE_SET")} exact={true} component={DepartmentRuleSet} />
                <AuthRoute allowedUserRoles={["Adminstrator"]} path={NavigationHelper.getRoute("DEBITOR_RULE_SET")} exact={true} component={DebitorRuleSets} />
                <AuthRoute allowedUserRoles={["Adminstrator", "Operation"]} path={NavigationHelper.getRoute("CAPACITY_OPTIMIZATION")} exact={true} component={CapacityOptimization} />
            </div>
            <AuthRoute
                allowedUserRoles={["Adminstrator", "CustomerService", "Stock", "Operation"]}
                path={NavigationHelper.getRoute("CALENDAR")}
                exact={true}
                component={Calendar}
            />
            <AuthRoute
                allowedUserRoles={["Adminstrator", "CustomerService", "Stock", "Operation"]}
                path={NavigationHelper.getRoute("MOBILE_SERVICE_MAP")}
                exact={true}
                component={MobileServiceMap}
            />
        

        </>
    );
};

export default Page;
