import BaseApi from "./baseApi";

interface IMediaAPI {
    uploadImage: (image: File, fileName: string, orderId: string, imageType: string) => Promise<string>;
    getOrderImages: (orderId: string, debitorId: string) => Promise<OrderImage[]>;
}


export default class MediaAPI extends BaseApi implements IMediaAPI {
    async getOrderImages(orderId: string, debitorId: string) : Promise<OrderImage[]>
    {
        const url = `/api/media/${orderId}/${debitorId}`;
        return await this.fetchGet(url);
    }
    async uploadImage(image: File, fileName: string, orderId: string, imageType: string): Promise<string> {
        const formData = new FormData();
        formData.append("file", image);
        formData.append("orderId", orderId);
        formData.append("imageType", imageType);
        formData.append("fileName", fileName);
        const url = `/api/media`;
        return await this.fetchPutFile(url, formData);
    }
}
