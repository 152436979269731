import { connect } from "react-redux";
import { Dispatch, Action } from "redux";
import {
    getOrder,
    getOperators,
    getDepartments,
    getCar,
    getLeasers,
    getInsurers,
    getDebitors,
    getProductSearchQuery,
    getProductList,
    getUserInformation,
    getCustomer,
    getOrderImages
} from "../../state/action";
import { RootState } from "../../state";
import * as React from "react";
import UnauthorizedWorkSheet from "./UnauthorizedWorkSheet";
import { saveSelectedOrder } from "../../state/worksheet/action";
import {
    loadOrder,
    updateOrder,
    setDiscount,
    removeProduct,
    setProductAmount,
    updateDebitor,
    updateCustomer,
    whoIsPayingChange,
    updateOrderDepartment,
    addProducts,
    removeImages,
    updateCar,
    updateDebitorFromWhoIsPaying,
    setDebitor,
    setProductPrice,
    setProductName,
    updateCustomerCityFromZip,
    updateTimeAndPlace,
    updateMobileServiceProduct,
    createMoreSaleOrder,
    addProductsAndUpdateOrder,
    
} from "../../state/order/action";
import {
    getDebitors as getDebitorsAction,
    updateProductSearchQuery
} from "../../state/ui/action";
import WorkSheet from ".";
import { RouteComponentProps } from "react-router";
import NavigationHelper from "../utils/NavigationHelper";
import { sendSms } from "../../state/sms/action";
import {
    sendNotificationEmail,
    sendReceiptEmail
} from "../../state/email/action";
import { updateSearchList } from "../../state/product/action";
import { updateOperators } from "../../state/user/action";
import { addLog } from "../../state/log/action";
import { updateDepartmentsList } from "../../state/department/action";
import { canBookNextDay } from "../utils/orderHelper";
import { IsOffer } from "../utils/statusHelper";
import { getImages } from "../../state/orderImages/action";


const WorkSheetInfo: React.FunctionComponent<StateProps &
    RouteComponentProps<{ orderId: string }>> = props => {
    React.useEffect(() => {
        if (props.operators.length === 0) {
            props.updateOperators();
        }
    }, []);

    React.useEffect(() => {
        if (props.order.additionalData.orderType) {
            if (props.order.additionalData.orderType === "REPAIR") {
                props.updateDepartmentsList(
                   {},
                   props.order.timeAndPlace.department && props.order.timeAndPlace.department.mobileService ? props.customer: undefined
                );
            } else {
                if (props.car.make && props.car.model)
                    props.updateDepartmentsList(
                        { inStockLocation: canBookNextDay(props.order.items), carMake: props.car.make, carModel: props.car.model, orderType: props.order.additionalData.orderType},
                        props.order.timeAndPlace.department && props.order.timeAndPlace.department.mobileService ? props.customer: undefined
                    );
            }
        }
        if(props.order.debitor.no)
        {
            props.getImages(props.match.params.orderId, props.order.debitor.no);
        }
    }, [
        props.customer.zipCode,
        props.customer.city,
        props.order.additionalData.orderNr,
        props.order.additionalData.orderType,
        props.order.items,
        props.car,
        props.order.debitor.no,
    ]);

    React.useEffect(() => {
        const orderId = props.match.params.orderId;
        props.loadOrder(orderId);
    }, [props.match.params.orderId]);

    React.useEffect(() => {
        if (IsOffer(props.order.additionalData.orderStatus)) {
            NavigationHelper.pushRoute("BOOKING");
        }
    }, [props.order.additionalData.orderNr]);

    if (props.order) {
        return <WorkSheet {...props} />;
    }

    return <UnauthorizedWorkSheet />;
};

const mapStateToProps = (state: RootState) => {
    const manufacturers: WindowManufacturer[] = [
        { name: "XYG", text: "XYG" },
        { name: "SAINT_GOBAIN", text: "Saint-Gobain" },
        { name: "AGC", text: "AGC" },
        { name: "PILKINGTON", text: "Pilkington" },
        { name: "FUYAO", text: "Fuyao" },
        { name: "NORDGLASS", text: "Nordglass" },
        { name: "OE_LOGO_RUDER", text: "OE (logo-ruder)" },
        { name: "OTHER", text: "Andet" }
    ];

    return {
        order: getOrder(state),
        manufacturers,
        operators: getOperators(state),
        departments: getDepartments(state),
        car: getCar(state),
        leasers: getLeasers(state),
        insurers: getInsurers(state),
        debitors: getDebitors(state),
        query: getProductSearchQuery(state),
        products: getProductList(state),
        user: getUserInformation(state),
        customer: getCustomer(state),
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    updateSearchList: (
        query: { searchIn: string; term: string },
        merge: boolean
    ) => dispatch<any>(updateSearchList(query, merge)),
    updateSearchProductQuery: (searchIn?: string, term?: string) =>
        dispatch<any>(updateProductSearchQuery(searchIn, term)),
    updateOrder: (order: Order) => dispatch<any>(updateOrder(order)),
    saveOrder: (order: Order, status?: OrderStatus) => dispatch<any>(saveSelectedOrder(order, status)),
    createMoreSaleOrder: (order: Order) =>
        dispatch<any>(createMoreSaleOrder(order)),
    loadOrder: (orderId: string) => dispatch<any>(loadOrder(orderId)),
   
    setDiscount: (itemNo: string, discount: number) =>
        dispatch(setDiscount(itemNo, discount)),
    removeProduct: (itemNo: string) => dispatch(removeProduct(itemNo)),
    setAmount: (itemNo: string, amount: number) =>
        dispatch(setProductAmount(itemNo, amount)),
    setPrice: (itemNo: string, price: number) =>
        dispatch(setProductPrice(itemNo, price)),
    setName: (itemNo: string, name: string) =>
        dispatch(setProductName(itemNo, name)),
    updateCustomer: (customer: Partial<Customer>) =>
        dispatch(updateCustomer(customer)),
    updateDebitor: (debitor: Partial<Debitor>, order: Order) =>
        dispatch<any>(updateDebitor(debitor, order)),
    whoIsPayingChange: (whoIsPaying: WhoIsPaying) =>
        dispatch<any>(whoIsPayingChange(whoIsPaying)),
    updateDebitorFromWhoIsPaying: (whoIsPaying: WhoIsPaying, order: Order, updatePrices: boolean) =>
        dispatch<any>(updateDebitorFromWhoIsPaying(whoIsPaying, order, updatePrices, false)),
    departmentChange: (department: Department, shouldUpdateDebitor: boolean) =>
        dispatch<any>(updateOrderDepartment(department, shouldUpdateDebitor)),
    addProducts: (products: Product[], debitorNo: string) =>
        dispatch<any>(addProducts(products, debitorNo)),
    addProductsAndUpdateOrder: (order: Order, products: Product[], debitorNo: string) =>
        dispatch<any>(addProductsAndUpdateOrder(order, products, debitorNo)),
    removeImages: (images: string[], imageType: ImageType) =>
        dispatch(removeImages(images, imageType)),
    updateCar: (carInfo: Car) => dispatch(updateCar(carInfo)),
    sendSms: (model: NotificationModel) => dispatch<any>(sendSms(model)),
    sendNotificationEmail: (model: NotificationModel) =>
        dispatch<any>(sendNotificationEmail(model)),
    sendReceiptEmail: (model: IOrderReceiptModel) =>
        dispatch<any>(sendReceiptEmail(model)),
    setDebitor: (debitor: Debitor, order: Order) =>
        dispatch<any>(setDebitor(debitor, order)),
    getDebitor: (query: string, type: ApiType) =>
        dispatch<any>(getDebitorsAction(query, type)),
    updateCustomerCityFromZipCode: (zipcode: string) =>
        dispatch<any>(updateCustomerCityFromZip(zipcode)),
    updateOperators: () => dispatch<any>(updateOperators()),
    addLog: (log: Log) => dispatch<any>(addLog(log)),
    updateTimeAndPlace: (timeAndPlace: Partial<TimeAndPlace>) =>
        dispatch<any>(updateTimeAndPlace(timeAndPlace)),
    updateMobileServiceProduct: (isMobileService: boolean, debitorNo: string, type: AssignmentType) =>
        dispatch<any>(updateMobileServiceProduct(isMobileService, debitorNo, type)),
    updateDepartmentsList: ( requestModel?: TimeslotFilterRequestModel, customerInfo?: Customer ) =>
        dispatch<any>(updateDepartmentsList(requestModel, customerInfo ) ),
    getImages: ( orderId: string, debitorId: string ) =>
        dispatch<any>(getImages(orderId, debitorId ) )
});


type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(WorkSheetInfo);
