export const getTrendIcon = (trend: number):{icon: string, color: string} => {
    if (trend <= -30)
    {
        return {icon: "Down", color: "red"};  // Large negative average change
    }
    else if (trend <= -10)
    {
        return {icon: "ArrowTallDownRight", color: "indianred"};  // Small negative average change
    }
    else if (trend > -10 && trend < 10)
    {
        return  {icon: "Forward",  color: "black" };  // No significant average change
    }
    else if (trend >= 10 && trend <= 30)
    {
        return { icon: "ArrowTallUpRight", color: "indianred" };  // Small positive average change
    }
    else if (trend > 30)
    {
        return  { icon: "Up", color: "red" };  // Large positive average change
    }
    
    return {color: "", icon: ""};  // Fallback in case of unexpected values
}