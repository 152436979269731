import BaseApi from "../baseApi";

export default class CapacityOptimizationApi extends BaseApi {
    async tryFindCapacityOptimizations(minDeliveryTime: number): Promise<CapacityOptimizationResult[]> {
        const url = `/api/CapacityOptimization/tryFind`;
        return await this.fetchPost(url, {
            minDeliveryTime
        });
    }
    async AddTrainingData(data: CapacityOptimizationTrainingModel): Promise<void> {
        const url = `/api/CapacityOptimization/AddTrainingData`;
        return await this.fetchPost(url, data);
    }

    async deliveryForcast(model: CalculateOptimizationRequestModel): Promise<{deliveries: DeliveryModelOptimiztion[], lookupDate: Date, stoneToReplaceDate: Date}> {
        const url = `/api/CapacityOptimization/DeliveryForcast`;
        return await this.fetchPost(url, model);
    }

}


