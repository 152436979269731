import {
    EventApi,
    EventClickArg,
    EventContentArg,
    EventDropArg
} from "@fullcalendar/core";
import * as React from "react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGrid from "@fullcalendar/timegrid";
import resource from "@fullcalendar/resource-timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import API from "../../api";
import { formatDate, prependZeroIFLessThen10 } from "../utils/DateHelper";
import { datesAreOnSameDay } from "../../util/DateHelper";
import "./style.css";
import FullCalendar from "@fullcalendar/react";

interface Props {
    departmentId?: string;
    updateId: string;
    showBookingEvent: (bookingId: string) => void;
    showEvent: (event: EventApi) => void;
    filters: string[];
    date: Date;
    view: string;
    departments: Department[];
    operator?: string;
    show24hours: boolean;
}
//every 5 min will it fetch the data
const refreshDelay = 1000 * 60 * 5;

const Calendar: React.FunctionComponent<Props> = props => {
    const calendarRef = React.useRef<any>(null);
    const [lastUpdated, setLastUpdated] = React.useState<Date>(new Date());
    const [view, setView] = React.useState<string>(props.view);
    const [date, setDate] = React.useState<Date>(props.date);

    const refetchData = () => {
        if (
            calendarRef &&
            calendarRef.current &&
            calendarRef.current !== null
        ) {
            const fullCalendar = calendarRef.current as FullCalendar;
            const calendarApi = fullCalendar.getApi();
            calendarApi.refetchEvents();
            setLastUpdated(new Date());
        }
    };

    React.useEffect(() => {
        const interval = setInterval(() => {
            refetchData();
        }, refreshDelay);
        return () => clearInterval(interval);
    }, []);

    React.useEffect(() => {
        if (
            calendarRef &&
            calendarRef.current &&
            calendarRef.current !== null &&
            props.date !== date
        ) {
            const fullCalendar = calendarRef.current as FullCalendar;
            const calendarApi = fullCalendar.getApi();
            calendarApi.gotoDate(props.date);
            setDate(props.date);
            setLastUpdated(new Date());
        }
    }, [props.date]);
    let setTimeoutView: any = undefined;
    React.useEffect(() => {
        if (
            calendarRef &&
            calendarRef.current &&
            calendarRef.current !== null &&
            props.view !== view
        ) {
            clearTimeout(setTimeoutView);
            setTimeoutView = setTimeout(() => {
                const fullCalendar = calendarRef.current as FullCalendar;
                const calendarApi = fullCalendar.getApi();
                calendarApi.changeView(props.view);
                setView(props.view);
                setLastUpdated(new Date());
            }, 200);
        }
    }, [props.view]);

    const eventClick = React.useCallback((value: EventClickArg) => {
        if (value.event.extendedProps.type === "BOOKING") {
            props.showBookingEvent(value.event.id);
        } else if (value.event.extendedProps.type === "DEPARTMENT") {
            props.showEvent(value.event);
        }
    }, []);

    const eventDrop = React.useCallback(async (value: EventDropArg) => {
        if (value.oldEvent.start && value.event.start) {
            // we only want to make the calendar changes on the same day
            if (!datesAreOnSameDay(value.oldEvent.start, value.event.start)) {
                alert("Du må kun ændre en aftale på dagen.");
                value.revert();
            } else {
                const time = `${prependZeroIFLessThen10(
                    value.event.start.getHours()
                )}:${prependZeroIFLessThen10(value.event.start.getMinutes())}`;
                await API.CalendarApi.updateBookingTime(
                    time,
                    value.event.id,
                    value.event.allDay
                );
            }
        }
        refetchData();
    }, []);

    const eventDrag = React.useCallback(async (value: any) => {
        const elementAtPoint: Element | null = document.elementFromPoint(
            value.jsEvent.x,
            value.jsEvent.y
        );
        const fromId = value.event.id;

        if (elementAtPoint && value.event.allDay) {
            const dragToElement = elementAtPoint.closest("a");
            if (dragToElement) {
                //AllDay container
                const container = (dragToElement.parentElement as HTMLElement)
                    .parentElement;

                if (container) {
                    const currentOrder = [];
                    for (let i = 0; i < container.children.length; i++) {
                        const element = container.children[i].children[0];
                        if (element) {
                            const id =
                                element.classList[element.classList.length - 1];
                            if (id.length === 36) {
                                //Makes sure that we have a Guid id
                                currentOrder.push(id);
                            }
                        }
                    }
                    const toId =
                        dragToElement.classList[
                            dragToElement.classList.length - 1
                        ];
                    if (toId.length === 36) {
                        //Makes sure that we have a Guid id
                        await API.CalendarApi.moveAllDayBooking({
                            fromId,
                            toId,
                            currentOrder
                        });
                        refetchData();
                    }
                }
            }
        }
    }, []);

    const getResources = React.useCallback(() => {
        const resources = [];
        if (props.filters.indexOf("WINDSCREEN") > -1) {
            resources.push({ id: "windscreen", title: "Mont" });
        }
        if (props.filters.indexOf("STONECHIP") > -1) {
            resources.push({ id: "stoneChip", title: "Rep" });
        }
        return resources;
    }, [props.filters]);

    if (!props.departmentId) {
        return null;
    }
    const currentDepartment = props.departments.find(
        x => x.id === props.departmentId
    );

    const renderEvent = (eventInfo: EventContentArg) => {
        const { event } = eventInfo;
        let bookingTime = "";
        let isShortTimeSlot = false;
        if (event.start && event.end) {
            isShortTimeSlot =
                event.end.getTime() / 100000 - event.start.getTime() / 100000 <=
                30;
            const startTime = formatDate(event.start);
            const endTime = formatDate(event.end);
            bookingTime = `${startTime.substring(
                0,
                startTime.indexOf(" ")
            )}-${endTime.substring(0, endTime.indexOf(" "))}`;
        }

        return (
            <>
                <div
                    className={`calendar_event ${
                        isShortTimeSlot ? "short_timeslot" : ""
                    }`}
                >
                    <p>{bookingTime}</p>
                    <p
                        className="content"
                        dangerouslySetInnerHTML={{ __html: event.title }}
                    ></p>
                    {event._def.extendedProps.inStock !== null &&
                    event._def.resourceIds &&
                    event._def.resourceIds[0] === "windscreen" &&
                    event._def.extendedProps.type === "BOOKING" ? (
                        <div className="stock_notification">
                            {eventInfo.event._def.extendedProps.inStock ? (
                                <>
                                    <i className="in_stock__icon">&#10003;</i>
                                    <p>På lager</p>
                                </>
                            ) : (
                                <>
                                    <i className="in_stock__icon">&#215;</i>
                                    <p>Ikke på lager</p>
                                </>
                            )}
                        </div>
                    ) : null}
                </div>
            </>
        );
    };

    return (
        <>
            <div key={props.departmentId}>
                <h3>{currentDepartment ? currentDepartment.name : ""}</h3>
                <FullCalendar
                    eventStartEditable={true}
                    ref={calendarRef}
                    schedulerLicenseKey={"0792512764-fcs-1638956019"}
                    slotLabelFormat={{
                        hour12: false,
                        hour: "2-digit",
                        minute: "2-digit",
                        omitZeroMinute: false,
                        meridiem: false
                    }}
                    resources={getResources()}
                    datesAboveResources={true}
                    headerToolbar={false}
                    locale={"da"}
                    firstDay={1}
                    contentHeight={"auto"}
                    handleWindowResize={true}
                    allDaySlot={true}
                    allDayText={"Hele dagen"}
                    plugins={[
                        dayGridPlugin,
                        interactionPlugin,
                        timeGrid,
                        resource
                    ]}
                    slotDuration={"00:15:00"}
                    eventOrder={["priority", "title"]}
                    slotMinTime={props.show24hours ? "00:00:00" : "06:00:00"}
                    views={{
                        day: {
                            type: "resourceTimeGridDay",
                            buttonText: "En Dag"
                        },
                        fiveDay: {
                            type: "resourceTimeGrid",
                            duration: { week: 1 },
                            buttonText: "5 dage",
                            weekends: false
                        },
                        week: {
                            type: "resourceTimeGrid",
                            buttonText: "En Uge",
                            duration: {
                                week: 1
                            }
                        }
                    }}
                    initialDate={date}
                    initialView={view}
                    eventSources={[
                        {
                            url: "/api/calendar",
                            method: "GET",
                            extraParams: {
                                departmentId: props.departmentId,
                                filters: props.filters,
                                worker: !props.operator ? "" : props.operator
                            }
                        }
                    ]}
                    eventContent={renderEvent}
                    eventClick={eventClick}
                    eventDrop={eventDrop}
                    eventDragStop={eventDrag}
                />

                <p className="last_updated">
                    Sidst opdateret: {lastUpdated.getHours()}:
                    {lastUpdated.getMinutes()}:{lastUpdated.getSeconds()}
                </p>
            </div>
        </>
    );
};

export default Calendar;