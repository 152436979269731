import { EventApi, formatDate } from "@fullcalendar/core";
import * as React from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { RootState } from "~/state";
import { setShowEvent, setCurrentBooking } from "../../state/calendar/action";

import Calendar from "./Calendar";
import useWindowDimensions from "./util/useWindowDimensions";
import "./style.css";

interface Props {
    departments: { id: string; updateId: string }[];
    allDepartments: Department[];
    filters: string[];
    operator?: string;
    show24hours: boolean;
}

const daysHandler = {
    day: 1,
    fiveDay: 7,
    week: 7
};

const CalendarWrapper: React.FunctionComponent<Props & StateProps> = props => {
    const [date, setDate] = React.useState(new Date());
    const [view, setView] = React.useState<"day" | "fiveDay" | "week">(
        "fiveDay"
    );
    const bookingEventClick = React.useCallback(
        (id: string) => props.setCurrentBooking(id),
        []
    );
    const onEventClick = React.useCallback(
        (event: EventApi) => props.setShowEvent(event),
        []
    );
    const windowDimensions = useWindowDimensions();
    const onTodayClick = React.useCallback(() => setDate(new Date()), []);
    const onPreviousClick = React.useCallback(() => {
        setDate(new Date(date.setDate(date.getDate() - daysHandler[view])));
    }, [view, date]);
    const onNextClick = React.useCallback(() => {
        setDate(new Date(date.setDate(date.getDate() + daysHandler[view])));
    }, [view, date]);

    const departmentsOverflowClass = props.departments.length > 1 ? "multiple-departments" : "";

    React.useEffect(() => {
        const mobileWidth = windowDimensions.width <= 768;
        if (mobileWidth) {
            setView("day");
        } else {
            setView("fiveDay");
        }
    }, [windowDimensions]);

    const onSetViewClick = React.useCallback(
        (view: "day" | "fiveDay" | "week") => () => setView(view),
        []
    );

    const currentDate = new Date();
    const isToday =
        currentDate.getDate() === date.getDate() &&
        currentDate.getMonth() === date.getMonth() &&
        currentDate.getFullYear() === date.getFullYear();
    return (
        <>
            <div className="calendar_header">
                <div className="fc fc-media-screen fc-direction-ltr fc-theme-standard">
                    <div className="fc-header-toolbar fc-toolbar fc-toolbar-ltr">
                        <div className="fc-toolbar-chunk">
                            <div className="fc-button-group">
                                <button
                                    onClick={onPreviousClick}
                                    type="button"
                                    title="Previous week"
                                    aria-pressed="false"
                                    className="fc-prev-button fc-button fc-button-primary"
                                >
                                    <span className="fc-icon fc-icon-chevron-left"></span>
                                </button>
                                <button
                                    onClick={onTodayClick}
                                    type="button"
                                    title="This week"
                                    disabled={isToday}
                                    aria-pressed="false"
                                    className="fc-today-button fc-button fc-button-primary"
                                >
                                    I dag
                                </button>
                                <button
                                    onClick={onNextClick}
                                    type="button"
                                    title="Next week"
                                    aria-pressed="false"
                                    className="fc-next-button fc-button fc-button-primary"
                                >
                                    <span className="fc-icon fc-icon-chevron-right"></span>
                                </button>
                            </div>
                        </div>
                        <div className="fc-toolbar-chunk">
                            <h2 className="fc-toolbar-title">
                                {formatDate(date, {
                                    month: "short",
                                    year: "numeric",
                                    day: "numeric",
                                    locale: "da"
                                })}
                            </h2>
                        </div>
                        <div className="fc-toolbar-chunk timetable-selector">
                            <div className="fc-button-group">
                                <button
                                    onClick={onSetViewClick("day")}
                                    type="button"
                                    title="En Dag view"
                                    className={
                                        "fc-day-button fc-button fc-button-primary " +
                                        (view === "day"
                                            ? "fc-button-active"
                                            : "")
                                    }
                                >
                                    En Dag
                                </button>
                                <button
                                    onClick={onSetViewClick("fiveDay")}
                                    type="button"
                                    title="5 dage view"
                                    className={
                                        "fc-fiveDay-button fc-button fc-button-primary " +
                                        (view === "fiveDay"
                                            ? "fc-button-active"
                                            : "")
                                    }
                                >
                                    5 dage
                                </button>
                                <button
                                    onClick={onSetViewClick("week")}
                                    type="button"
                                    title="En Uge view"
                                    className={
                                        "fc-week-button fc-button fc-button-primary " +
                                        (view === "week"
                                            ? "fc-button-active"
                                            : "")
                                    }
                                >
                                    En Uge
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`calendar_wrapper ${view === "day" ? "one-day-view" : ""} ${departmentsOverflowClass}`}>
                <div className="calendars">
                    {props.departments.map((department, index) => (
                        <Calendar
                            show24hours={props.show24hours}
                            operator={props.operator}
                            departments={props.allDepartments}
                            date={date}
                            view={view}
                            key={index}
                            filters={props.filters}
                            updateId={department.updateId}
                            departmentId={department.id}
                            showEvent={onEventClick}
                            showBookingEvent={bookingEventClick}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {};
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    setShowEvent: (event: EventApi) => dispatch<any>(setShowEvent(event)),
    setCurrentBooking: (id: string) => dispatch<any>(setCurrentBooking(id))
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(CalendarWrapper);