import BaseApi from "../baseApi";


export default class DepartmentResourceRelationApi extends BaseApi {
    async get(
        departmentId: string,
    ): Promise<DepartmentResourceRelation[]> {
        const url = `/api/DepartmentResourceRelation/get/${departmentId}`;
        return await this.fetchGet(url);
    }

    async delete(id: string): Promise<string> {
        const url = `/api/DepartmentResourceRelation/delete/${id}`;
        return await this.fetchDelete(url);
    }
    async add(data: DepartmentResourceRelation): Promise<DepartmentResourceRelation> {
        const url = `/api/DepartmentResourceRelation/add`;
        return await this.fetchPost(url, data);
    }
}
