import produce from "immer";
import { Actions } from "./action";

export interface DepartmentState {
    departments: Department[];
    alreadyUsedTimeslot?: AlreadyUsedTimeslot;
    departmentResourceRelation?: DepartmentResourceRelation[];
}

const initialState: DepartmentState = {
    departments: [],
};

export const reducer = (
    departmentState = initialState,
    action: Actions
): DepartmentState =>
    produce(departmentState, draft => {
        switch (action.type) {
            case "UPDATE_DEPARTMENTS_LIST": {
                if (action.departments.length > 0) {
                    draft.departments = action.departments;
                }
                return;
            }
            case "UPDATE_DEPARTMENT": {
                var index = draft.departments.findIndex(
                    x => x.id === action.department.id
                );
                if (index === -1) {
                    draft.departments.push(action.department);
                    return;
                }
                draft.departments[index] = action.department;
                return;
            }
            case "DELETE_DEPARTMENT": {
                var index = draft.departments.findIndex(
                    x => x.id === action.department.id
                );
                if (index === -1) {
                    return;
                }
                draft.departments.splice(index, 1);
                return;
            }
            case "ADD_TIMESLOTS": {
                const departmentIndex = draft.departments.findIndex(
                    x => x.id === action.departmentId
                );
                if (departmentIndex === -1) {
                    return;
                }
                const department: any = draft.departments[departmentIndex];
                department[action.timeslotType] = [...department[action.timeslotType], ...action.timeslots];
                return;
            }
            case "UPDATE_TIMESLOT": {
                const departmentIndex = draft.departments.findIndex(
                    x => x.id === action.departmentId
                );
                if (departmentIndex === -1) {
                    return;
                }
                const department: any = draft.departments[departmentIndex];
                const timeslots: DamageSpecificTimeSlot[] = department[action.timeslotType];

                if (timeslots) {
                    action.timeSlots.forEach(timeslot => {
                        const timeslotIndex = timeslots.findIndex(x => x.id === timeslot.id);
                        const tempvalue = department[action.timeslotType];
                        if (timeslotIndex > -1) {
                            const { id, ...rest } = timeslot;
                            tempvalue[timeslotIndex] = timeslot;
                        }
                        else {
                            tempvalue.push(timeslot);
                        }
                    });

                }
                return;
            }
            case "REMOVE_TIMESLOT": {
                const departmentIndex = departmentState.departments.findIndex(
                    x => x.id === action.departmentId
                );
                if (departmentIndex === -1) {
                    return;
                }
                const department = departmentState.departments[departmentIndex];
                const timeslots: DamageSpecificTimeSlot[] = (department as any)[action.timeslotType];

                if (timeslots) {
                    const timeslotIndex = timeslots.findIndex(x => x.id === action.timeslotId);
                    ((draft.departments[departmentIndex] as any)[action.timeslotType]).splice(timeslotIndex, 1);
                }
                return;
            }
            case "ALREADY_USED_TIMESLOT":
                {
                    draft.alreadyUsedTimeslot = action.alreadyUsedTimeslot;
                    return;
                }
            case "GET_DEPARTMENT_RESOURCE_RELATION":
                {
                    draft.departmentResourceRelation = action.ResourceRelation;
                    return;
                }
            case "ADD_DEPARTMENT_RESOURCE_RELATION":
                {
                    if (draft.departmentResourceRelation) {
                        draft.departmentResourceRelation.push(action.ResourceRelation);
                    }
                    else {

                        draft.departmentResourceRelation = [action.ResourceRelation];
                    }
                    return;
                }
            case "DELETE_DEPARTMENT_RESOURCE_RELATION":
                {
                    if (draft.departmentResourceRelation) {
                        draft.departmentResourceRelation = draft.departmentResourceRelation.filter(x => x.id !== action.id);
                        console.log(draft.departmentResourceRelation, action);
                    }
                    return;
                }

        }
    });

export const getDepartments = (state: DepartmentState) => state.departments;

export const getAlreadyUsedTimeslots = (state: DepartmentState) => state.alreadyUsedTimeslot;

export const getDepartmentResourceRelation = (state: DepartmentState) => state.departmentResourceRelation === undefined ? [] : state.departmentResourceRelation;

export default reducer;
