import * as React from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { RootState } from "../../../../state";
import { getOrderImages } from "../../../../state/action";
import "./style.css";
import { CompoundButton, Stack } from "@fluentui/react";
import ImageDialog from "./imageDialog";
import { uploadImage } from "../../../../state/orderImages/action";
import { missingImage } from "./util/ImageUtil";

interface Props {
    orderId?: string;
}

const OrderImages: React.FunctionComponent<Props & StateProps> = props => {
    const [showDialog, setShowDialog] = React.useState(false);
    const ontoggleDialogClick = () => setShowDialog(!showDialog);

    const missingImages = missingImage(props.orderImages);

    const onUploadImage = (file: File,fileName: string, imageType: string) => {
        if(props.orderId)
        {
            props.uploadImage(file, fileName, props.orderId, imageType);
        }
    }
    if (!props.orderId) {
        return null;
    }

    return <>
        <ImageDialog onUploadFile={onUploadImage} orderImages={props.orderImages} showDialog={showDialog} toggleDialog={ontoggleDialogClick} />
        <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Stack.Item grow={1}>
                {missingImages === 0 ?
                    <CompoundButton onClick={ontoggleDialogClick} className="imageBtn" primary={false} secondaryText="klik for at tilføje yderligere billeder" iconProps={{ iconName: "Camera", }}>
                        Billeder Ok
                    </CompoundButton>
                    :
                    <CompoundButton onClick={ontoggleDialogClick} className="missingImageBtn" primary={false} secondaryText="klik for at tilføje billeder" iconProps={{ iconName: "Camera", }}>
                        Billeder mangler
                    </CompoundButton>
                }
            </Stack.Item>
        </Stack>
    </>;
}
const mapStateToProps = (state: RootState) => {
    return {
        orderImages: getOrderImages(state),
    };
};
const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    uploadImage: (file: File, fileName: string, orderId: string, imageType: string) =>
        dispatch<any>(uploadImage(file, fileName, orderId, imageType))
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(OrderImages);
