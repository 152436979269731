import produce from "immer";
import { Actions } from "./action";

export interface CapacityOptimizationState {
    capacityOptimizationResult: CapacityOptimizationResult[];
    deliveryForcast: DeliveryModelOptimiztion[];
    lookupDate?: Date;
    stoneToReplaceDate?: Date;

 
}

const initialState: CapacityOptimizationState = {
   capacityOptimizationResult: [],
   deliveryForcast: [],
   
};

export const reducer = (state = initialState, action: Actions): CapacityOptimizationState => produce(state, draft => {
   if(action.type === "TRY_FIND_CAPACITYOPTIMIZATION_RESULT")
   {
        draft.capacityOptimizationResult = action.result.map(x=>({...x, type : "MOVE_WORKER"}));
        return;
   }
   if(action.type === "DELIVERY_FORCAST")
   {
    const { deliveries, lookupDate, stoneToReplaceDate} = action;
        draft.deliveryForcast = deliveries;
        draft.lookupDate = lookupDate;
        draft.stoneToReplaceDate = stoneToReplaceDate;
        return;
   }
   
});

export const getCapacityOptimizationResult = (state: CapacityOptimizationState) =>
    state.capacityOptimizationResult;

export const getDeliveryForcast = (state: CapacityOptimizationState) =>
    state.deliveryForcast;

export const getDeliveryForcastLookupDate = (state: CapacityOptimizationState) =>
    state.lookupDate;

export const getDeliveryForcastStoneToReplaceDate = (state: CapacityOptimizationState) =>
    state.stoneToReplaceDate;

export default reducer;