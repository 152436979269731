import * as React from "react";
import { FreeCarSku, Types } from "../../../typings";
import Comment from "../../../fields/Comment";

import { useEffect } from "react";
import { useState } from "react";

import { getDropdownForOperators } from "../../../utils/operatorsHelper";
import { getDropdownForWindshieldManufacturer } from "../../../utils/manufacturerHelper";
import "./style.css";
import {
    IDropdownOption,
    Stack,
    Label,
    Dropdown,
    TextField
} from "@fluentui/react";
import Feedback from "../feedback";
import OrderImages from "../OrderImages";
import OrderButtons from "./OrderButtons";

interface Props {
    orderId?: string;
    status?: Types;
    externalOrderComment: string;
    internalOrderComment: string;
    guaranteeId: string;
    operators: UserInformation[];
    manufacturers: WindowManufacturer[];
    timeSpent: number;
    orderStatus: OrderStatus;
    workKm: number;
    order: Order;
    products: ProductGroup[];
    user: UserInformation;
    query: { searchIn?: string; term?: string };
    sendSms: (model: NotificationModel) => void;
    sendNotificationEmail: (model: NotificationModel) => void;
    onSaveOrderClick: (status?: OrderStatus) => void;
    updateAdditionalData: (
        additionalDataFieldName: string,
        newValue: any
    ) => void;
    updateSearchProductQuery: (searchIn?: string, term?: string) => void;
    updateSearchList: (
        query: { searchIn: string; term: string },
        merge: boolean
    ) => void;
    saveOrder: (order: Order) => void;
    addProducts: (products: Product[], debitorNo: string) => void;
    removeProduct: (itemNo: string) => void;
}

function OrderComplete(props: Props) {
    const [highlightWorkerField, setHighlightWorkerField] = useState<boolean>(false);
    const [highlightWindowField, setHighlightWindowField] = useState<boolean>(false);

    useEffect(() => {
        const { timeSlotId, department } = props.order.timeAndPlace;

        if (
            department &&
            department.windowChangeTimeOffset &&
            department.specificWindowReplaceTimeSlots
        ) {
            const selectedTimeSlot = department
                ? department.windowReplaceTimeSlots.find(
                      x => x.id === timeSlotId
                  ) ||
                  department.specificWindowReplaceTimeSlots.find(
                      x => x.id === timeSlotId
                  )
                : undefined;

            const isOrderItemAdded =
                props.order.items.findIndex(
                    x => x.itemNo === FreeCarSku.CAR_ONE
                ) > -1;

            if (!isOrderItemAdded) {
                if (selectedTimeSlot && selectedTimeSlot.hasFreeCar) {
                    props.addProducts(
                        [
                            {
                                name: "INKL. GRATIS LÅNEBIL",
                                description: "",
                                isService: true,
                                twoPersonJob: false,
                                previouslyPurchased: false,
                                amount: 1,
                                type: 0,
                                no: FreeCarSku.CAR_ONE,
                                price: 0,
                                status: []
                            }
                        ],
                        props.order.debitor.no
                    );
                }
            } else {
                if (
                    (selectedTimeSlot && selectedTimeSlot.hasFreeCar == null) ||
                    false
                ) {
                    props.removeProduct(FreeCarSku.CAR_ONE);
                }
            }
        }
    }, [props.order.timeAndPlace]);

    useEffect(() => {
        if (!props.order.additionalData.windowManufacturer)
            setHighlightWindowField(true);
        else if (props.order.additionalData.windowManufacturer === "NONE")
            setHighlightWindowField(true);
        else setHighlightWindowField(false);
    }, [props.order.additionalData.windowManufacturer]);

    useEffect(() => {
        props.order.additionalData.worker
            ? setHighlightWorkerField(false)
            : setHighlightWorkerField(true);
    }, [props.order.additionalData.worker]);

   

    const updateAdditionalData = (additionalDataFieldName: string) => (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => props.updateAdditionalData(additionalDataFieldName, value);

    const setComment = (additionalDataFieldName: string) => (value: string) =>
        props.updateAdditionalData(additionalDataFieldName, value);

    const updateAdditionalDataNumber = (additionalDataFieldName: string) => (
        e: React.SyntheticEvent<HTMLInputElement>,
        value: string
    ) => {
        const numericValue = parseFloat(value);
        props.updateAdditionalData(additionalDataFieldName, numericValue);
    };

    const setTimeUsed = (
        e: React.SyntheticEvent<HTMLInputElement>,
        option: IDropdownOption
    ) => props.updateAdditionalData("timeSpent", option.key as number);

    const setWorker = (
        e: React.SyntheticEvent<HTMLInputElement>,
        option: IDropdownOption
    ) => props.updateAdditionalData("worker", option.key);

    const setHelper = (
        e: React.SyntheticEvent<HTMLInputElement>,
        option: IDropdownOption
    ) => props.updateAdditionalData("helper", option.key);

    const setWindowManufacturer = (
        e: React.SyntheticEvent<HTMLInputElement>,
        option: IDropdownOption
    ) => props.updateAdditionalData("windowManufacturer", option.key);

    const timeSpentInterval = 15;
    const array = Array.from({ length: 12 }, (v, k) => k + 1);
    const timeSpentOptions: IDropdownOption[] =
        //maks available = 180
        array.map((value, index) => ({
            key: (index + 1) * timeSpentInterval,
            text: `${(index + 1) * timeSpentInterval} min.`,
            isSelected: props.timeSpent === (index + 1) * timeSpentInterval
        }));
    timeSpentOptions.push({
        key: 999,
        text: "180 min.+",
        isSelected: props.timeSpent === 999
    });

    if (props.order) {
        return (
            <div className="work__complete">
                
                <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <Stack.Item grow={1}>
                        <Label>Montør:</Label>
                        <Dropdown
                            calloutProps={{
                                calloutMaxHeight: 250,
                                calloutMinWidth: 100
                            }}
                            className={
                                highlightWorkerField ? "highlighted" : ""
                            }
                            options={getDropdownForOperators(props.operators)}
                            onChange={setWorker}
                            defaultSelectedKey={
                                props.order.additionalData.worker
                            }
                        />

                        {props.order.additionalData.twoManJob && (
                            <>
                                <Label>Hjælper:</Label>
                                <Dropdown
                                    calloutProps={{
                                        calloutMaxHeight: 250,
                                        calloutMinWidth: 100
                                    }}
                                    options={getDropdownForOperators(
                                        props.operators
                                    )}
                                    onChange={setHelper}
                                    defaultSelectedKey={
                                        props.order.additionalData.helper
                                    }
                                />
                            </>
                        )}

                        <Label>Km:</Label>
                        <TextField  type="number" value={props.workKm.toString()} onChange={updateAdditionalDataNumber("workKM")} />

                        {(props.order.additionalData.orderType.startsWith(
                            "REPLACE"
                        ) ||
                            (props.order.complaint &&
                                props.order.complaint.complaintType ===
                                    "REPLACE_SLOT") ||
                            (props.order.additionalData.orderType.startsWith(
                                "COMPLAINT"
                            ) &&
                                props.order.complaint &&
                                props.order.complaint.complaintType ===
                                    "WITHOUT_SLOT" &&
                                props.order.complaint.referenceJobType &&
                                props.order.complaint.referenceJobType.startsWith(
                                    "REPLACE"
                                ))) && (
                            <>
                                <Label>Rudefabrikat:</Label>
                                <Dropdown
                                    calloutProps={{
                                        calloutMaxHeight: 250,
                                        calloutMinWidth: 100
                                    }}
                                    className={
                                        highlightWindowField
                                            ? "highlighted"
                                            : ""
                                    }
                                    options={getDropdownForWindshieldManufacturer(
                                        props.manufacturers
                                    )}
                                    onChange={setWindowManufacturer}
                                    defaultSelectedKey={
                                        props.order.additionalData
                                            .windowManufacturer
                                    }
                                />
                            </>
                        )}
                    </Stack.Item>
                    <Stack.Item grow={1}>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Stack.Item grow={1}>
                                <Label>Tidsforbrug</Label>
                                <Dropdown
                                    calloutProps={{
                                        calloutMaxHeight: 250,
                                        calloutMinWidth: 100
                                    }}
                                    defaultSelectedKey={
                                        props.timeSpent ? props.timeSpent : ""
                                    }
                                    placeholder={"Vælg tidsforbrug"}
                                    onChange={setTimeUsed}
                                    options={timeSpentOptions}
                                />
                            </Stack.Item>
                        </Stack>
                        <Comment
                            onChange={setComment("externalOrderComment")}
                            predefindedText={[]}
                            title="Extern kommentar:"
                            value={props.externalOrderComment}
                        />
                        <br />
                        <Comment
                            onChange={setComment("internalOrderComment")}
                            predefindedText={[]}
                            title="Intern Kommentar:"
                            value={props.internalOrderComment}
                        />
                    </Stack.Item>
                    <Stack.Item grow={1}>
                        <Label>Garanti nr:</Label>
                        <TextField
                            value={props.guaranteeId}
                            onChange={updateAdditionalData("guaranteeId")}
                        />
                        <br />
                        <Label></Label>
                        <OrderImages orderId={props.orderId} />
                    </Stack.Item>
                </Stack>
                <br />
                <br />
                <Stack
                    className="finish-order-buttons"
                    horizontal
                    horizontalAlign="end"
                    tokens={{ childrenGap: 20 }}
                >
                    <Feedback orderId={props.orderId} worker={props.order.additionalData.worker} />
                    <OrderButtons 
                        onSaveOrderClick={props.onSaveOrderClick}
                        order={props.order}
                        orderStatus={props.orderStatus}
                        sendNotificationEmail={props.sendNotificationEmail}
                        sendSms={props.sendSms}
                        user={props.user}
                        products={props.products}
                        query={props.query}
                        saveOrder={props.saveOrder}
                        updateAdditionalData={props.updateAdditionalData}
                        updateSearchList={props.updateSearchList}
                        updateSearchProductQuery={props.updateSearchProductQuery}
                         />
                </Stack>
            </div>
        );
    }
    return null;
}

export default OrderComplete;
