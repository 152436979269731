import * as React from "react";

import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";

interface Props {
    hideDialog: boolean;
    onDismiss: () => void;
    onConfirm: () => void;
}

const ImageIsMissingDialog: React.FunctionComponent<Props> = props => {
    const dialogContentProps = {
        type: DialogType.normal,
        title: "Advarsel",
        subText: "Du kan ikke afslutte din ordre, før der er tilføjet billeder",
    };

    return (
        <Dialog modalProps={{className: "missingImageDialog"}} minWidth={500} hidden={props.hideDialog} dialogContentProps={dialogContentProps} onDismiss={props.onDismiss}>
            <DialogFooter>
                <PrimaryButton  className="goBackAndAddMissingImage" onClick={props.onConfirm} text="Nej, Gå tilbage og tilføj billederne" />
                <DefaultButton className="forgotToAddImages" onClick={props.onDismiss} text="Forsæt, jeg har glemt at tage billeder" />
            </DialogFooter>
        </Dialog>
    );
   
}

export default ImageIsMissingDialog;
