import * as React from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { RootState } from "../../../../state";
import { getOrderImages } from "../../../../state/action";
import "./style.css";

interface Props {

}

const InfoBar: React.FunctionComponent<Props & StateProps> = props => {
    const missingImages = props.orderImages.filter(x =>  (!x.url || x.url.length === 0) && x.imageType !== "Custom");
    if (missingImages.length === 0) {
        return null;
    }

    return <>
        <h2 className="missing_images">Der mangler at blive tilføjet {missingImages.length} {missingImages.length === 1 ? "billede" : "billeder"} på denne ordre</h2>
    </>;
}
const mapStateToProps = (state: RootState) => {
    return {
        orderImages: getOrderImages(state),
    };
};
const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
});


type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(InfoBar);
