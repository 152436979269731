import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state";
import { getDepartmentResourceRelation } from "../../state/action";
import { addDepartmentResourceRelationAction, deleteDepartmentResourceRelationAction, getDepartmentResourceRelationAction } from "../../state/department/action";
import { DefaultButton, Dialog, DialogType, Label, PrimaryButton, Stack, StackItem } from "@fluentui/react";
import ReactSelectDropdown, { Option } from "../calendar/util/ReactSelectDropdown";

interface Props {
    departmentId?: string;
    departments: Department[]
}
const DepartmentReleations = (props: Props) => {
    const departmentResureces = useSelector((state: RootState) => getDepartmentResourceRelation(state));
    const [editMode, setEditMode] = React.useState(false);
    const [selectedDepartment, setSelectedDepartment] = React.useState<Option | undefined>(undefined);

    const dispatch = useDispatch() as any;
    React.useEffect(() => {
        if (props.departmentId) {
            dispatch(getDepartmentResourceRelationAction(props.departmentId));
        }
    }, [props.departmentId])

    const onDepartmentChange = (selectedOption: Option) => {
        console.log(selectedOption);
        setSelectedDepartment(selectedOption);
    }
    const editRessource = () => setEditMode(!editMode);
    const onDeleteReleationClick = (id: string) => () => {
        dispatch(deleteDepartmentResourceRelationAction(id));
    }
    const onAddReleationClick = () => {
        if (selectedDepartment && props.departmentId) {
            dispatch(addDepartmentResourceRelationAction(props.departmentId, selectedDepartment.value as string));
            setSelectedDepartment(undefined);
        }
    }

    const getResurecesList = () => {
        if (departmentResureces.length === 0) {
            return "Tilføj Ressource";
        }
        let result = departmentResureces.map(x => {
            var department = props.departments.find(y => y.id === x.resourceId)
            if (department) {
                return department.navLocationCode;
            }
        });
        return result.join(", ");

    }
    const departmentOption = props.departments.filter(x => x.id !== props.departmentId && departmentResureces.find(y => y.resourceId === x.id) === undefined &&x.parentDepartmentId === null).map(
        option => ({
            value: option.id as string,
            label: option.name
        })
    );

    const menuTitleText = selectedDepartment && selectedDepartment.label ? selectedDepartment.label : "Vælg en afdeling";
    return <>
        <Label>Ext Ressource:</Label>
        <span onClick={editRessource}>{getResurecesList()}</span>
        <Dialog minWidth={400} hidden={!editMode} onDismiss={editRessource} dialogContentProps={{
            type: DialogType.largeHeader,
            title: "Ext Afdelings Ressourcer",
            showCloseButton:true
        }}>
            <div className="department-releations">
                {departmentResureces && departmentResureces.map(x => {
                    var department = props.departments.find(y => y.id === x.resourceId)
                    if (department) {
                        return <DefaultButton key={x.id} iconProps={{ iconName: "Delete" }} onClick={onDeleteReleationClick(x.id)}>{department.navLocationCode}</DefaultButton>;
                    }
                })}
                <Stack horizontal={true} tokens={{ padding: 5, childrenGap: 10 }}>
                    <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                        <ReactSelectDropdown
                            options={departmentOption}
                            isSearchable={true}
                            value={""}
                            label={menuTitleText || ""}
                            onChange={onDepartmentChange}
                            isDisabled={false}
                            placeholder={""}
                        />
                    </StackItem>
                    <StackItem className="departmentStackItem" grow={false} disableShrink={true} >

                        <PrimaryButton disabled={!selectedDepartment} onClick={onAddReleationClick}>Tilføj</PrimaryButton>
                    </StackItem>
                </Stack>

            </div>
        </Dialog>
    </>;
}

export default DepartmentReleations;
