import * as React from "react";
import { Fragment, useState } from "react";
import DepartmentOpeningHours from "./DepartmentOpeningHours";
import DepartmentTimeSlots from "./DepartmentTimeSlots";
import { getDepartmentDropdownOptions } from "../utils/departmentHelper";
import DropdownWithNew from "../base/DropdownPanel/DropdownWithNew";
import { getRegionDropdownList } from "./helpers/region";
import { userHasAccess } from "../utils/UserHelper";
import { CanEditAndCreateDepartment } from "./Departments";
import { deliveringDays } from "./helpers/openingHoursDayDropdown";
import { Checkbox, Dropdown, IDropdownOption, Label, PrimaryButton, Stack, StackItem, TextField } from "@fluentui/react";
import "./style.css";
import DepartmentReleations from "./DepartmentReleations";

interface DepartmentProps {
    alreadyUsedTimeslot?: AlreadyUsedTimeslot;
    department: Department;
    departments: Department[];
    userInformation: UserInformation;
    updateDepartment: (department: Department) => void;
    addOrUpdateTimeslot: (departmentId: string, type: string, timeslot: DamageSpecificTimeSlot) => void;
    deleteTimeslot: (departmentId: string, type: string, timeslotId: string) => void;
    onSaveClick: () => void;
}

const Department: React.FunctionComponent<DepartmentProps> = props => {
    const [saveState, setSaveState] = useState<"SAVED" | "UNSAVED">("SAVED");
    const [relatedDepartmentsOptions, setRelatedDepartmentsOptions] = useState<IDropdownOption[]>([]);

    React.useEffect(() => {
        const relatedDepartments = props.departments.filter(
            x => x.navResponsibilityCenter === props.department.navResponsibilityCenter && x.id !== props.department.id
        );
        let dropdownOptions: IDropdownOption[] = getDepartmentDropdownOptions(
            relatedDepartments
        );
        dropdownOptions = [
            ...dropdownOptions,
            {
                text: "Ingen",
                key: ""
            }
        ];
        setRelatedDepartmentsOptions(dropdownOptions);
    }, []);
    const { department, updateDepartment } = props;
    const onSaveClick = () => {
        setSaveState("SAVED");
        props.onSaveClick();
    };

    const onPropertyChange = (value: any, property: string) => {
        if (department) {
            const result: Department | any = { ...department };
            switch (property) {
                case "mobileService":
                    // Map boolean to boolean
                    result[property] = value;

                    // reset parent department if it's not a mobile service
                    if (!value) {
                        result["parentDepartmentId"] = undefined;
                    }
                    break;
                case "lat":
                case "lng":
                    // Map string to float
                    result[property] = parseFloat(value);
                    break;
                case "windowChangeTimeOffset":
                case "stoneChipTimeOffset":
                    // Map string to int
                    result[property] = parseInt(value);
                    break;
                default:
                    result[property] = value;
                    break;
            }
            updateDepartment(result);
            setSaveState("UNSAVED");
        }
    };

    const onMainDepartmentChange = (
        event: React.FormEvent<HTMLDivElement>,
        option: IDropdownOption | undefined
    ) => {
        const foundDepartment = props.departments.find(
            x => x.id === (option ? option.key.toString() : undefined)
        );

        onPropertyChange(
            foundDepartment ? foundDepartment.id : undefined,
            "parentDepartmentId"
        );
    };

    const onTimeSlotChange = (
        timeSlots: DamageSpecificTimeSlot[],
        propertyName: string
    ) => {
        // sort by Date or "day" with the second sort "from" and the third "to"
        switch (propertyName) {
            case "specificOpeningHours":
            case "specificWindowReplaceTimeSlots":
            case "specificStoneChipTimeSlots":
                timeSlots = timeSlots.sort(
                    (a, b) =>
                        (a.date !== undefined
                            ? new Date(a.date).getTime()
                            : 0) -
                        (b.date !== undefined ? new Date(b.date).getTime() : 0)
                );
                break;
            default:
                timeSlots = timeSlots.sort(
                    (a, b) => a.day - b.day || a.from - b.from || a.to - b.to
                );
                break;
        }

        onPropertyChange(timeSlots, propertyName);
    };

    const toggleDeliveringDays = (weekDay: string) => () => {

        let deliveringDays = department.deliveringDays
            ? [...department.deliveringDays.split(",")]
            : [];
        if (deliveringDays.indexOf(weekDay) === -1) {
            deliveringDays = [...deliveringDays, weekDay];
        } else {
            deliveringDays = deliveringDays.filter(x => x !== weekDay);
        }
        onPropertyChange(deliveringDays.join(","), "deliveringDays");
    };

    const valueChangeHandler = (propertyName: string) => (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        value: any
    ) => onPropertyChange(value, propertyName);

    const regionValueChangeHandler = (value: string) =>
        onPropertyChange(value, "region");

    const onBooleanPropertyChange = (value: string) => (ev?: React.FormEvent<HTMLInputElement> | undefined, checked?: boolean) => {
        const result: Department | any = { ...department };
        if (checked !== undefined) {
            result[value] = checked;
            updateDepartment(result);
            setSaveState("UNSAVED");
        }
    }

    const addOrUpdateTimeslot = (type: string, timeslot: DamageSpecificTimeSlot) => {
        if (props.department.id) {
            props.addOrUpdateTimeslot(props.department.id, type, timeslot);
        }
    }
    const deleteTimeslot = (type: string, timeslotId: string) => {
        if (props.department.id) {
            props.deleteTimeslot(props.department.id, type, timeslotId);
        }

    }
    return (
        <Fragment>
            <div className="departmentSaveWrap">
                <Label>{saveState === "SAVED" ? "Gemt" : "Ikke gemt"}</Label>
                <PrimaryButton disabled={saveState !== "UNSAVED"} onClick={onSaveClick} >Gem</PrimaryButton>
            </div>
            {userHasAccess(
                props.userInformation.roles,
                CanEditAndCreateDepartment
            ) && (
                    <div className="custom_container">
                        <Stack horizontal tokens={{ padding: 5, childrenGap: 10 }}>
                            <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                                <Label>Navn</Label>
                                <TextField type="text" value={department.name} onChange={valueChangeHandler("name")} />
                            </StackItem>
                            <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                                <Label>Adresse</Label>
                                <TextField type="text" value={department.address} onChange={valueChangeHandler("address")} />
                            </StackItem>
                            <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                                <Label>OBS</Label>
                                <TextField type="text" value={department.obs} onChange={valueChangeHandler("obs")} />
                            </StackItem>
                            <StackItem  grow={false} disableShrink={false} >
                                <Label>Services:</Label>
                                <div className="departmentWeekDays">
                                    <Checkbox label="Kaffe?" checked={department.coffee} onChange={onBooleanPropertyChange("coffee")} />
                                    <Checkbox label="Wifi?" checked={department.wifi} onChange={onBooleanPropertyChange("wifi")} />
                                    <Checkbox label="Låne bil?" checked={department.loanCar} onChange={onBooleanPropertyChange("loanCar")} />
                                </div>
                            </StackItem>

                        </Stack>
                        <Stack horizontal tokens={{ padding: 5, childrenGap: 10 }}>
                            <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                                <Label>Telefonnr.</Label>
                                <TextField type="text" value={department.phone} onChange={valueChangeHandler("phone")} />
                            </StackItem>
                            <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                                <Label>E-mail</Label>
                                <TextField type="text" value={department.departmentEmail} onChange={valueChangeHandler("departmentEmail")} />
                            </StackItem>
                            <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                                <Label>Max Antal Medarbejder</Label>
                                <TextField type="number" value={department.maxNumberOfWorkers.toString()} onChange={valueChangeHandler("maxNumberOfWorkers")} />
                            </StackItem>
                        </Stack>
                        <Stack horizontal tokens={{ padding: 5, childrenGap: 10 }}>
                            <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                                <Label>Mobil Service</Label>
                                <Checkbox className="departmentCheckbox" label="" checked={department.mobileService} onChange={valueChangeHandler("mobileService")} />
                            </StackItem>
                            <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                                <Label>Kort placering (Breddegrad)</Label>
                                <TextField type="number" step="0.01" value={department.lat.toString()} onChange={valueChangeHandler("lat")} />
                            </StackItem>
                            <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                                <Label>Kort placering (Længdegrad)</Label>
                                <TextField type="number" step="0.01" value={department.lng.toString()} onChange={valueChangeHandler("lng")} />
                            </StackItem>
                        </Stack>
                        <Stack horizontal tokens={{ padding: 5, childrenGap: 10 }}>
                            <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                                <Label>Navision lokation kode</Label>
                                <TextField
                                    value={department.navLocationCode}
                                    onChange={valueChangeHandler("navLocationCode")}
                                />
                            </StackItem>
                            <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                                <Label>Navision ansvarscenter kode</Label>
                                <TextField
                                    disabled={true}
                                    value={department.navResponsibilityCenter}
                                    onChange={valueChangeHandler("navResponsibilityCenter")}
                                />
                            </StackItem>
                            <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                                <Label>Navision fødelager kode</Label>
                                <TextField value={department.navBirthLocationCode} onChange={valueChangeHandler("navBirthLocationCode")} />
                            </StackItem>
                            <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                                <Label>Navision debitor id</Label>
                                <TextField
                                    value={department.debitorId}
                                    onChange={valueChangeHandler("debitorId")}
                                />
                            </StackItem>
                        </Stack>
                        <Stack horizontal tokens={{ padding: 5, childrenGap: 10 }}>
                            <StackItem className="departmentWindowOffset" grow={true} disableShrink={true} >
                                <Label>Window Replacement Offset</Label>
                                <TextField

                                    type="number"
                                    value={department.windowChangeTimeOffset.toString()}
                                    onChange={valueChangeHandler(
                                        "windowChangeTimeOffset"
                                    )}
                                />
                            </StackItem>
                            <StackItem
                                className="departmentStackItem" grow={true} disableShrink={true} >
                                <Label>Stone Chip Fix Offset</Label>
                                <TextField
                                    type="number"
                                    value={department.stoneChipTimeOffset.toString()}
                                    onChange={valueChangeHandler(
                                        "stoneChipTimeOffset"
                                    )}
                                />
                            </StackItem>
                        </Stack>

                        <Stack horizontal tokens={{ padding: 5, childrenGap: 10 }}>
                            <StackItem className="departmentStackItem" grow={true} disableShrink={true}>
                                <Label>Næste dag booking tidspunkt</Label>
                                <TextField type="time" value={department.nextDayDeliveryTime} onChange={valueChangeHandler("nextDayDeliveryTime")} />
                            </StackItem>
                            <StackItem className="departmentStackItem" grow={true} disableShrink={true}>
                                <Label>Daglig leveringtidspunkt</Label>
                                <TextField type="time" value={department.dailyDeliveryTime} onChange={valueChangeHandler("dailyDeliveryTime")} />
                            </StackItem>
                        </Stack>
                        <Stack horizontal tokens={{ padding: 5, childrenGap: 10 }}>
                            <StackItem className="departmentWeekDays" grow={true} disableShrink={true} >
                                <Label>Levering Dage</Label>
                                {deliveringDays.map(x => (
                                    <Checkbox
                                        key={x.key}
                                        checked={
                                            department.deliveringDays &&
                                                department.deliveringDays.indexOf(
                                                    x.key
                                                ) > -1
                                                ? true
                                                : false
                                        }
                                        label={x.text}
                                        onChange={toggleDeliveringDays(x.key)}
                                    />
                                ))}
                            </StackItem>
                        </Stack>

                        <Stack horizontal tokens={{ padding: 5, childrenGap: 10 }}>
                            <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                                <Label>Region</Label>
                                <DropdownWithNew
                                    selectedValue={department.region}
                                    options={getRegionDropdownList(
                                        props.departments,
                                        department.region
                                    )}
                                    newValueText="Tilføj ny Region"
                                    dialogTitle="Ny Region"
                                    onValueChange={regionValueChangeHandler}
                                ></DropdownWithNew>
                            </StackItem>
                            <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                                <Label>Venteliste</Label>
                                <Checkbox
                                    className="departmentCheckbox"
                                    label=""
                                    checked={department.hasWaitingList}
                                    onChange={valueChangeHandler("hasWaitingList")}
                                />
                            </StackItem>
                            <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                                <Label>Lager mail</Label>
                                <TextField
                                    type="text"
                                    value={department.warehouseEmailList}
                                    onChange={valueChangeHandler(
                                        "warehouseEmailList"
                                    )}
                                />
                            </StackItem>
                        </Stack>

                        <Stack horizontal tokens={{ padding: 5, childrenGap: 10 }}>
                            <StackItem grow={true} disableShrink={false} >
                                <Label>Aktiv:</Label>
                                <Checkbox label="Inaktiv?" checked={department.inActive} onChange={onBooleanPropertyChange("inActive")} />
                            </StackItem>
                           <StackItem grow={true} disableShrink={false} >
                                    <DepartmentReleations departments={props.departments} departmentId={department.id} />
                            </StackItem>
                        </Stack>

                        {department.mobileService && (
                            <Stack tokens={{ padding: 5, childrenGap: 10 }}>
                                <StackItem className="departmentStackItem" grow={true} disableShrink={true} >
                                    <Label>Hovedafdeling</Label>
                                    <Dropdown
                                        calloutProps={{ calloutMaxHeight: 250, calloutMinWidth: 100 }}
                                        placeholder="Vælg afdeling"
                                        options={relatedDepartmentsOptions}
                                        onChange={onMainDepartmentChange}
                                        selectedKey={department.parentDepartmentId}
                                    />
                                </StackItem>
                            </Stack>
                        )}
                    </div>
                )}
            {department.parentDepartmentId === null && (
                <>
                    <DepartmentOpeningHours
                        timeSlots={department.openingHours}
                        updateTimeSlots={onTimeSlotChange}
                        propertyName={"openingHours"}
                        enableDate={false}
                        pageTitle={"Åbningstider"}
                    />

                    <DepartmentOpeningHours
                        timeSlots={department.specificOpeningHours}
                        updateTimeSlots={onTimeSlotChange}
                        propertyName={"specificOpeningHours"}
                        enableDate={true}
                        pageTitle={"Undtagelser for åbningstider"}
                    />

                    <DepartmentTimeSlots
                        departmentId={department.id}
                        addOrUpdateTimeslot={addOrUpdateTimeslot}
                        deleteTimeslot={deleteTimeslot}
                        timeSlots={department.windowReplaceTimeSlots}
                        alreadyUsedTimeslot={props.alreadyUsedTimeslot}
                        propertyName={"windowReplaceTimeSlots"}
                        enableDate={false}
                        pageTitle={"Vinduesskiftsbookings"}
                    />

                    <DepartmentTimeSlots
                        departmentId={department.id}
                        addOrUpdateTimeslot={addOrUpdateTimeslot}
                        deleteTimeslot={deleteTimeslot}
                        timeSlots={department.specificWindowReplaceTimeSlots}
                        alreadyUsedTimeslot={props.alreadyUsedTimeslot}
                        propertyName={"specificWindowReplaceTimeSlots"}
                        enableDate={true}
                        pageTitle={"Undtagelser for vinduesskiftsbookings"}
                    />

                    <DepartmentTimeSlots
                        departmentId={department.id}
                        addOrUpdateTimeslot={addOrUpdateTimeslot}
                        deleteTimeslot={deleteTimeslot}
                        alreadyUsedTimeslot={props.alreadyUsedTimeslot}
                        timeSlots={department.stoneChipTimeSlots.filter(
                            c =>
                                c.parentSlotId === null ||
                                c.parentSlotId === undefined
                        )}
                        propertyName={"stoneChipTimeSlots"}
                        enableDate={false}
                        pageTitle={"Stenslagsbookings"}
                    />

                    <DepartmentTimeSlots
                        departmentId={department.id}
                        addOrUpdateTimeslot={addOrUpdateTimeslot}
                        deleteTimeslot={deleteTimeslot}
                        alreadyUsedTimeslot={props.alreadyUsedTimeslot}
                        timeSlots={department.specificStoneChipTimeSlots.filter(
                            c =>
                                c.parentSlotId === null ||
                                c.parentSlotId === undefined
                        )}
                        propertyName={"specificStoneChipTimeSlots"}
                        enableDate={true}
                        pageTitle={"Undtagelser for stenslagsbookings"}
                    />
                </>
            )}

            <div className="departmentSaveWrap">
                <Label>{saveState === "SAVED" ? "Gemt" : "Ikke gemt"}</Label>
                <PrimaryButton
                    disabled={saveState !== "UNSAVED"}
                    onClick={onSaveClick}
                >
                    Gem
                </PrimaryButton>
            </div>
        </Fragment>
    );
};

export default Department;
