import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import Page from "./components/layout";
import { createStore } from "./state";
import { getInitialData } from "./state/user/action";
import { initializeIcons, loadTheme } from "@fluentui/react";

//import Page from "./components/Page";
initializeIcons();

//loadTheme({});
loadTheme({
    palette: {
        themePrimary: "#0078d4",
        themeLighterAlt: "#eff6fc",
        themeLighter: "#deecf9",
        themeLight: "#c7e0f4",
        themeTertiary: "#71afe5",
        themeSecondary: "#2b88d8",
        themeDarkAlt: "#106ebe",
        themeDark: "#005a9e",
        themeDarker: "#004578",
        neutralLighterAlt: "#f8f8f8",
        neutralLighter: "#f4f4f4",
        neutralLight: "#eaeaea",
        neutralQuaternaryAlt: "#dadada",
        neutralQuaternary: "#d0d0d0",
        neutralTertiaryAlt: "#c8c8c8",
        neutralTertiary: "#c2c2c2",
        neutralSecondary: "#858585",
        neutralPrimaryAlt: "#4b4b4b",
        neutralPrimary: "#333333",
        neutralDark: "#272727",
        black: "#1d1d1d",
        white: "#ffffff"
    }
});

const store = createStore();
store.dispatch<any>(getInitialData());

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Switch>
                <Route path="/" component={Page} />
            </Switch>
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);
