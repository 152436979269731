import * as React from "react";
import { useState, Fragment, useEffect, useCallback } from "react";
import { Action, Dispatch } from "redux";
import {
    Dropdown,
    IDropdownOption,
    Label,
    Stack,
    StackItem
} from "@fluentui/react";
import { connect } from "react-redux";
import { getAlreadyUsedTimeslots, getDepartments, getUserInformation } from "../../state/action";
import { RootState } from "~/state";
import {
    addOrUpdateTimeslot,
    deleteDepartment,
    deleteTimeSlot,
    loadAlreadyUsedTimeslot,
    updateDepartment,
    updateDepartmentsList
} from "../../state/department/action";
import PageHeader from "../layout/PageHeader";
import { getDepartmentDropdownOptions } from "../utils/departmentHelper";
import NewDepartment from "./NewDepartment";
import Department from ".";
import { userHasAccess } from "../utils/UserHelper";
import "./style.css";
import { RouteComponentProps } from "react-router";
import NavigationHelper from "../utils/NavigationHelper";

export const CanEditAndCreateDepartment: UserRole[] = ["Adminstrator"];

const Departments = (props: StateProps &  RouteComponentProps<{ departmentId: string }>) => {
    const [currentDepartment, setCurrentDepartment] = useState<Department>();

    const [
        departmentsDropdownOptions,
        setDepartmentsDropdownOptions
    ] = useState<IDropdownOption[]>([]);
    useEffect(() =>{
        props.updateDepartmentsList();
    }, [])
    useEffect(() =>{
       if(props.match.params.departmentId &&  props.departments)
        {
            const department = props.departments.find(x=>x.id === props.match.params.departmentId);
            if(department)
            {
                props.loadAlreadyUsedTimeslot(props.match.params.departmentId);
                setCurrentDepartment(department);
              
            }
          
        }
    }, [props.match.params.departmentId, props.departments])
    useEffect(() => {
        let departments = [...props.departments];
        if (
            userHasAccess(props.userInformation.roles, ["TimeManagement"]) &&
            !userHasAccess(props.userInformation.roles, ["Adminstrator"])
        ) {
        
            const department = props.departments.find(
                x => props.userInformation.departmentGuid ? x.id === props.userInformation.departmentGuid : x.navLocationCode === props.userInformation.departmentId
            );
            if (department) {
                let mainNavCenter = department.navResponsibilityCenter;
                if (department.mobileService) {
                    mainNavCenter = mainNavCenter.slice(0, -1);
                }
                if(department.id)
                {
                    NavigationHelper.pushRoute("DEPARTMENTS",  `/${department.id.toString()}`);
                }
                departments = props.departments.filter(
                    x => x.navResponsibilityCenter.indexOf(mainNavCenter) > -1
                );
            } else {
                departments = [];
            }
            props.userInformation.departmentId;
        }

        const dropdownOptions: IDropdownOption[] = getDepartmentDropdownOptions(
            departments
        );
        setDepartmentsDropdownOptions(dropdownOptions);

      
    }, [props.departments]);

    const onDepartmentChanged = (
        event: React.FormEvent<HTMLDivElement>,
        option: IDropdownOption | undefined
    ) => {
        if (option) 
        {
            NavigationHelper.pushRoute("DEPARTMENTS",  `/${option.key.toString()}`);
        }
    };

    const onSaveClick = useCallback(() => {
        if (currentDepartment) {
            props.updateDepartment(currentDepartment);
        }
    }, [currentDepartment]);

    const renderDropdown = useCallback(() => {
        const currentSelectedKey = currentDepartment
            ? currentDepartment.id
            : undefined;
        return (
            <div className="custom_container">
                <Stack
                    className="departmetnHeaderStack"
                    horizontal
                    tokens={{ padding: 5, childrenGap: 10 }}
                >
                    <StackItem
                        className="departmentStackItem"
                        grow={true}
                        disableShrink={true}
                    >
                        <Label>Afdeling</Label>
                        <Dropdown
                            calloutProps={{calloutMaxHeight: 250, calloutMinWidth: 100}}
                            placeholder="Vælg afdeling"
                            options={departmentsDropdownOptions}
                            onChange={onDepartmentChanged}
                            selectedKey={currentSelectedKey}
                        />
                    </StackItem>
                    {userHasAccess(
                        props.userInformation.roles,
                        CanEditAndCreateDepartment
                    ) && (
                        <NewDepartment
                            setCurrentDepartment={setCurrentDepartment}
                        />
                    )}
                </Stack>
            </div>
        );
    }, [currentDepartment, departmentsDropdownOptions, props.departments]);

    const renderDetails = useCallback(() => {
        if (currentDepartment) {
            return (
                <Department
                    alreadyUsedTimeslot={props.alreadyUsedTimeslot}
                    department={currentDepartment}
                    onSaveClick={onSaveClick}
                    departments={props.departments}
                    updateDepartment={setCurrentDepartment}
                    userInformation={props.userInformation}
                    addOrUpdateTimeslot={props.addOrUpdateTimeslot}
                    deleteTimeslot={props.deleteTimeslot}
                />
            );
        }
    }, [currentDepartment,  props.alreadyUsedTimeslot]);
    return (
        <Fragment>
            {/* Header */}
            <PageHeader currentPage={"Tilpas afdeling"} noBreadcrumb={true} />
            {/* Dropdown */}
            {renderDropdown()}
            {/* Detail */}
            {renderDetails()}
        </Fragment>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        departments: getDepartments(state),
        userInformation: getUserInformation(state),
        alreadyUsedTimeslot: getAlreadyUsedTimeslots(state)
    };
};

const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
    updateDepartment: (department: Department) =>
        dispatch<any>(updateDepartment(department)),
    addOrUpdateTimeslot: (departmentId: string, type: string, timeslot: DamageSpecificTimeSlot) =>
        dispatch<any>(addOrUpdateTimeslot(departmentId, type, timeslot)),
    deleteTimeslot: (departmentId: string, type: string, timeslotId: string) =>
        dispatch<any>(deleteTimeSlot(departmentId, type, timeslotId)),
    deleteDepartment: (department: Department) =>
        dispatch<any>(deleteDepartment(department)),
    updateDepartmentsList: () =>
        dispatch<any>(updateDepartmentsList()),
    loadAlreadyUsedTimeslot: (departmentId:string) =>
        dispatch<any>(loadAlreadyUsedTimeslot(departmentId))
});

type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(Departments);
