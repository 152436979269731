import api from "../../api";
import { Action, Dispatch } from "redux";

export interface UpdateDepartmentsListAction extends Action {
    type: "UPDATE_DEPARTMENTS_LIST";
    departments: Department[];
}

export interface UpdateDepartmentAction extends Action {
    type: "UPDATE_DEPARTMENT";
    department: Department;
}

export interface DeleteDepartmentAction extends Action {
    type: "DELETE_DEPARTMENT";
    department: Department;
}

export interface UpdateTimeslotAction extends Action {
    type: "UPDATE_TIMESLOT";
    departmentId: string;
    timeSlots: DamageSpecificTimeSlot[];
    timeslotType: string;
}
export interface AddTimeSlotsAction extends Action {
    type: "ADD_TIMESLOTS";
    departmentId: string;
    timeslots: DamageSpecificTimeSlot[];
    timeslotType: string;
}
export interface RemoveTimeslotAction extends Action {
    type: "REMOVE_TIMESLOT";
    departmentId: string;
    timeslotId: string;
    timeslotType: string;
}
export interface AlreadyUsedTimeslotAction extends Action {
    type: "ALREADY_USED_TIMESLOT";
    alreadyUsedTimeslot: AlreadyUsedTimeslot;
}

export interface LoadDepartmentResourceRelationAction  extends Action {
    type: "GET_DEPARTMENT_RESOURCE_RELATION";
    ResourceRelation: DepartmentResourceRelation[];
}

export interface AddDepartmentResourceRelationAction  extends Action {
    type: "ADD_DEPARTMENT_RESOURCE_RELATION";
    ResourceRelation: DepartmentResourceRelation;
}
export interface DeleteDepartmentResourceRelationAction extends Action
{
    type: "DELETE_DEPARTMENT_RESOURCE_RELATION";
    id: string;
}

export const updateDepartment = (department: Department) => async (
    dispatch: Dispatch
) => {
    const editedDepartment = await api.DepartmentApi.updateDepartment(
        department
    );
    if (editedDepartment) {
        const action: UpdateDepartmentAction = {
            type: "UPDATE_DEPARTMENT",
            department: editedDepartment
        };
        dispatch(action);
    }
};


export const addOrUpdateTimeslot = (departmentId: string, type: string, timeslot: DamageSpecificTimeSlot) => async ( dispatch: Dispatch) => {
    const updatedTimeslot =  await api.DepartmentApi.addOrUpdateTimeSlot(departmentId, type, timeslot);
    const action: UpdateTimeslotAction = { type: "UPDATE_TIMESLOT", departmentId,  timeSlots: updatedTimeslot,  timeslotType: type };
    dispatch(action);
};

export const deleteTimeSlot = (departmentId: string, type: string, timeslotId: string) => async (dispatch: Dispatch) => {
    await api.DepartmentApi.deleteTimeSlot(departmentId, type, timeslotId);
    const action: RemoveTimeslotAction = { type: "REMOVE_TIMESLOT", departmentId,  timeslotType: type,  timeslotId };
    dispatch(action);
};

export const copyTimeSlot = (departmentId: string, dayOfWeek: number, copyTodate: Date, timeslotType: string) => async (dispatch: Dispatch) => {
    const result = await api.DepartmentApi.copyTimeslot(departmentId, dayOfWeek, copyTodate, timeslotType);
    const action: AddTimeSlotsAction = { type: "ADD_TIMESLOTS", departmentId,  timeslots: result,  timeslotType: timeslotType };
    dispatch(action);
};




export const deleteDepartment = (department: Department) => async (
    dispatch: Dispatch
) => {
    await api.DepartmentApi.deleteDepartment(department);
    const action: DeleteDepartmentAction = {
        type: "DELETE_DEPARTMENT",
        department
    };
    dispatch(action);
};

export let getDepartmentRequestStatus: AbortController | undefined = undefined;

export const updateDepartmentsList = (requestModel?: TimeslotFilterRequestModel, customer?: Customer) => async (dispatch: Dispatch) => {
    if (getDepartmentRequestStatus) {
        getDepartmentRequestStatus.abort("multiple calls, cancel the first one");
    }
    let searchCriteria = undefined;
    if (customer) {
        const { zipCode, city } = customer;
        searchCriteria = zipCode ? zipCode : city ? city : undefined;
    }
    getDepartmentRequestStatus = new AbortController();
    
    const departments = await api.DepartmentApi.getNearestLocation({...requestModel, searchCriteria},
        getDepartmentRequestStatus.signal
    );
    getDepartmentRequestStatus = undefined;
    const action: UpdateDepartmentsListAction = {
        type: "UPDATE_DEPARTMENTS_LIST",
        departments
    };
    dispatch(action);
};

export const loadAlreadyUsedTimeslot = (departmentId: string) => async (
    dispatch: Dispatch
) => {
    
    var result = await api.DepartmentApi.alreadyUsedTimeslots(departmentId);
    const action: AlreadyUsedTimeslotAction = {
        type: "ALREADY_USED_TIMESLOT",
        alreadyUsedTimeslot: result
    };
    dispatch(action);
};


export const getDepartmentResourceRelationAction = (departmentId: string) => async (dispatch: Dispatch) => {
    
    var result = await api.DepartmentResourceRelationApi.get(departmentId);
    const action: LoadDepartmentResourceRelationAction = {
        type: "GET_DEPARTMENT_RESOURCE_RELATION",
        ResourceRelation: result
    };
    dispatch(action);
};

export const deleteDepartmentResourceRelationAction = (id: string) =>async (dispatch: Dispatch) => {
    
    console.log("tester123", id);
    await api.DepartmentResourceRelationApi.delete(id);
    console.log("huhu", id);
    const action: DeleteDepartmentResourceRelationAction = {
        type: "DELETE_DEPARTMENT_RESOURCE_RELATION",
        id: id
    };
    dispatch(action);
};

export const addDepartmentResourceRelationAction = (departmentId: string, resourceId: string) =>async (dispatch: Dispatch) => {
    
    const result = await api.DepartmentResourceRelationApi.add({departmentId, resourceId} as DepartmentResourceRelation);
    const action: AddDepartmentResourceRelationAction = {
        type: "ADD_DEPARTMENT_RESOURCE_RELATION",
        ResourceRelation: result
    };
    dispatch(action);
};


export type Actions =
    | UpdateDepartmentsListAction
    | UpdateDepartmentAction
    | DeleteDepartmentAction
    | UpdateTimeslotAction
    | RemoveTimeslotAction
    | AddTimeSlotsAction
    | AlreadyUsedTimeslotAction
    | LoadDepartmentResourceRelationAction
    | AddDepartmentResourceRelationAction
    | DeleteDepartmentResourceRelationAction;
