import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckboxVisibility, DatePicker, DayOfWeek, DetailsList, DetailsListLayoutMode, IColumn, Icon, Label, PrimaryButton, Stack, StackItem, TextField } from "@fluentui/react";
import { RootState } from "../../state";
import { getCapacityOptimizationResult, getUserInformation } from "../../state/action";
import { addDays, getNextWeekday } from "../utils/DateHelper";
import { saveTrainingDataModel, tryFindCapacityOptimizations } from "../../state/capacityOptimization/action";
import { Fragment } from "react";
import PageHeader from "../layout/PageHeader";
import TableHelper from "../utils/TableHelper";
import "./style.css";
import { getTrendIcon } from "./trendHelper";
import SolutionDialog from "./SolutionDialog";

const CapacityOptimization = () => {
    const CapacityOptimizationResult = useSelector((state: RootState) => getCapacityOptimizationResult(state));
    const user = useSelector((state: RootState) => getUserInformation(state));
    const [minDeliveryTime, setMinDeliveryTime] = React.useState<string>("3");
    const [capacityOptimizationTrainingModel, setCapacityOptimizationTrainingModel] = React.useState<CapacityOptimizationResult | undefined>();
    const dispatch = useDispatch() as any;

    const onMinDeliveryTimeChange = (_dummy: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => setMinDeliveryTime(value);
   
    const onLookForCapacityOptimizationClick = () => dispatch(tryFindCapacityOptimizations(parseInt(minDeliveryTime)))
    
    const clearCapacityOptimizationTrainingModel = () => setCapacityOptimizationTrainingModel(undefined);
    const saveUpdatedTrainingModel = (model: CapacityOptimizationTrainingModel) =>
    {
        dispatch(saveTrainingDataModel( {...model, createdBy: user.name}))
        clearCapacityOptimizationTrainingModel();
    };

    const onSolutionClick = (model: CapacityOptimizationResult) => () => setCapacityOptimizationTrainingModel(model);
    const columns: IColumn[] = [
      
        TableHelper.col("departmentName", "Afdeling", 260, 260, (e: CapacityOptimizationResult) => {
            return <Label>{e.name}</Label>;
        }),
        TableHelper.col("toWindowDeliveryTime", "Leveringtid rudeskift", 200, 200, (e: CapacityOptimizationResult) => {
            return <Label>{e.toWindowDeliveryTime}</Label>;
        }),
        TableHelper.col("Tendens", "Tendens", 80, 80, (e: CapacityOptimizationResult) => {
            const icon =  getTrendIcon(e.trendRepairValue);
            return <Icon style={{color:icon.color }} iconName={icon.icon} />;
        }),
        TableHelper.col("toWorkLoadScore ", "Leveringtid Stenslag", 200, 200, (e: CapacityOptimizationResult) => {
            return <Label>{e.toStoneChipDeliveryTime}</Label>;
        }),
        TableHelper.col("Tendens2", "Tendens", 80, 80, (e: CapacityOptimizationResult) => {
            const icon =  getTrendIcon(e.trendRepairValue);
            return <Icon style={{color:icon.color }} iconName={icon.icon} />;
        }),
        TableHelper.col("", "", 160, 160, (e: CapacityOptimizationResult) => {
            return <div className="capacityListBtn">
                <PrimaryButton onClick={onSolutionClick(e)}>Se løsningsforslag</PrimaryButton>
            
            </div>; 
            }),
    ];
    const filteredList = (): CapacityOptimizationResult [] =>
    {
        return CapacityOptimizationResult.reduce((prev, current) =>
        {
            if(prev.find(x=>x.toDepartment === current.toDepartment)=== undefined)
            {
                if(current.toStoneChipDeliveryTime > current.toWindowDeliveryTime
                    ||  current.toWindowDeliveryTime > 0
                )
                {
                    return [...prev, current];
                }
            }
            return prev;
           

        }, [] as CapacityOptimizationResult[]);
    }
    return <Fragment>
        <PageHeader currentPage={"Kapacitetsoptimering"} noBreadcrumb={true} />
        <div className="custom_container">
            <Stack horizontal tokens={{ padding: 5, childrenGap: 10 }}>
              
                <StackItem grow={true} disableShrink={true} >
                    <TextField prefix="Min leverings tid" type="number" max={10} min={0} value={minDeliveryTime} onChange={onMinDeliveryTimeChange} />
                </StackItem>
                <StackItem grow={true} disableShrink={true} >
                    <PrimaryButton onClick={onLookForCapacityOptimizationClick}>Søg efter optimeringsmuligheder</PrimaryButton>
                </StackItem>
            </Stack>
            <DetailsList
                className="hidden-xs assignments"
                layoutMode={DetailsListLayoutMode.fixedColumns}
                columns={columns}
                items={filteredList()}
                checkboxVisibility={CheckboxVisibility.hidden}
                compact={false}

            />
        </div>
        {capacityOptimizationTrainingModel && <SolutionDialog 
         
            onDismiss={clearCapacityOptimizationTrainingModel} 
            saveTrainingModel={saveUpdatedTrainingModel} 
            traningModel={capacityOptimizationTrainingModel} 
            optimizationOptions={CapacityOptimizationResult.filter(x=>x.toDepartment === capacityOptimizationTrainingModel.toDepartment)} />}
    </Fragment>;
}

export default CapacityOptimization;