import * as React from "react";
import "./style.css";
import { CompoundButton, DefaultButton, Dialog, Stack } from "@fluentui/react";
import imageCompression from "browser-image-compression";
import useWindowDimensions from "../../../calendar/util/useWindowDimensions";

interface Props {
    showDialog: boolean;
    toggleDialog: () => void;
    orderImages: OrderImage[];
    onUploadFile: (file: File, fileName: string, imageType: string) => void;
}

const ImageDialog: React.FunctionComponent<Props> = props => {
    const [showTab, setShowTab] = React.useState(0);
    const inputFileRef = React.useRef(null);
    const inputFileEnvironment = React.useRef(null);
    const [visableImageIndex, setVisableImageIndex] = React.useState(0);
    const windowDimensions = useWindowDimensions();

    const image = props.orderImages[showTab];

    if (!image) {
        return null;
    }
    const onTabClick = (index: number) => () => setShowTab(index);

    const onUploadClick = () => {
        if (inputFileRef) {
            (inputFileRef as any).current.click();
        }
    }

    const onCameraClick = () => {
        if (inputFileEnvironment) {
            (inputFileEnvironment as any).current.click();
        }
    }

    const updateImageIndex = (direaction: "NEXT" | "PREV") => () => {
        if (image.url && image.url.length > 0) {
            let index = 0;
            switch (direaction) {
                case 'NEXT':
                    index = (visableImageIndex + 1) % image.url.length;
                    break;
                case 'PREV':
                    index = (visableImageIndex == 0) && image.url.length - 1 || visableImageIndex - 1;
                    break;
            }
            setVisableImageIndex(index);
        }
    }

    const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const imageFile = event.target.files[0];

            console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
            console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            }
            try {
                const compressedFile = await imageCompression(imageFile, options);
                console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
                console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

                props.onUploadFile(compressedFile, imageFile.name, image.imageType); // write your own logic
            } catch (error) {
                console.log(error);
            }
        }

    }
    return <>
        <Dialog minWidth={600} hidden={!props.showDialog} onDismiss={props.toggleDialog} dialogContentProps={{
            showCloseButton: true,
            title: <>
                {props.orderImages.map((x, index) => {
                    const imageCount = index + 1;
                    let className = "imageTab";
                    if (x.imageType !== "Custom" && (!x.url || x.url.length === 0)) {
                        className += " tabMissingImage";
                    }

                    if (index === showTab) {
                        className += " selectedTab";
                    }
                    return <DefaultButton onClick={onTabClick(index)} key={index} className={className}>{imageCount}</DefaultButton>
                })}
            </>
        }} >
            <div key={showTab}>
                <div className="image-upload-descrition">
                    <h2>{image.title ? image.title : ""} {image.imageType === "Custom" ? "" : showTab + 1}</h2>
                    <p>{image.description ? image.description : ""}</p>
                </div>
                <Stack horizontal={windowDimensions.width <= 768 ? false : true} tokens={{ childrenGap: 20 }}>
                    <Stack.Item grow={1}>
                        {image.url && image.url.length > 0 ?
                            <div className="imageContainer">
                                <span>Upload Image</span>
                                {image.url.map((x, index) => {
                                    const className = index === visableImageIndex ? "visableImage" : "hiddenImage";
                                    return <img className={className} width={300} src={x}></img>;
                                })}
                                {image.url.length > 1 &&
                                    <>
                                        <DefaultButton className="imagePrevious" iconProps={{ iconName: "Previous", }} onClick={updateImageIndex("PREV")}></DefaultButton>
                                        <DefaultButton className="imageNext" iconProps={{ iconName: "Next", }} onClick={updateImageIndex("NEXT")}></DefaultButton>
                                        <span className="currentImage">{visableImageIndex + 1} / {image.url.length}</span>
                                    </>
                                }
                            </div>
                            :
                            <>
                                <span>Eksempel</span>
                                <img  width={300} src={require(`../../../../media/Insurrance/${image.imageType}.jpg`)}></img>
                            </>
                        }

                    </Stack.Item>
                    <Stack.Item grow={1}>
                        <CompoundButton onClick={onCameraClick} className="uploadbtn" primary={true} iconProps={{ iconName: "Camera", }}>
                            Tag et billede
                        </CompoundButton>
                        <CompoundButton onClick={onUploadClick} className="uploadbtn" primary={true} iconProps={{ iconName: "Upload", }}>
                            Upload billede
                        </CompoundButton>
                        <input ref={inputFileEnvironment} hidden={true} capture="environment" type="file" accept="image/*" onChange={handleImageUpload}></input>

                        <input ref={inputFileRef} id="uploadFile" hidden={true} type="file" accept="image/*" onChange={handleImageUpload}></input>
                    </Stack.Item>
                </Stack>
            </div>
        </Dialog>
    </>;
}

export default ImageDialog;